<app-header-one></app-header-one>

<div class="page-title-area page-title-life">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Life Insurance</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Life Insurance</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>Life policy is a multiple-line insurance policy</h3>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Randomised words which don't slightly believable.</p> -->
                        <p>Life insurance is the best way to create wealth & secure family’s future in the event of unfortunate death of the policyholder. Life insurance can be availed either through “Term plans” that offer life cover for family’s protection or through “Investment Plans” that help in wealth creation with financial security to meet individuals financial goals.</p>
                        <p>Life insurance is an agreement between an individual and an insurance company under which the insurance company promises to provide a sum assured (death benefit) to the family of the policyholder in the event of the policyholder’s unforeseen death. The plan also offers a survival benefit to the insured if he/she survives the policy term. Thus, life insurance provides financial protection to an individual and his/her loved ones against the uncertainties of life.</p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p> -->
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some for.</p> -->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="image text-center">
                        <img src="assets/img/services-image/life1.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="insurance-details-desc">
            <h3>Key Features & Benefits of Life Insurance</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>Before investing your money in a life insurance plan, you must choose a plan that fulfils your insurance requirements without bothering your budget. Below mentioned are some tips which can help you choose the right life insurance plan for yourself:</p>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i> Financial Security</li>
                        <li><i class="fas fa-check"></i>Death Benefit</li>
                        <li><i class="fas fa-check"></i>Maturity Benefits</li>
                        <li><i class="fas fa-check"></i>Guaranteed Returns</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Wealth Creation</li>
                        <li><i class="fas fa-check"></i>Tax Benefits</li>
                        <li><i class="fas fa-check"></i>Flexible premium payment option</li>
                        <li><i class="fas fa-check"></i>Retirement Planning</li>
                    </ul>
                </div>
            </div>

            <h3>Setting the mood with incense</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Every life insurance policy is different and so are their premiums. The reason why no two insurance premiums are the same is because there are a lot of factors that are considered while calculating an insurance premium. Some of the factors responsible for this are the age of the policyholder, policy duration, type of policy, policyholders debts, if any, etc.
                Many companies provide life insurance premium calculators on their websites wherein a policy aspirant can calculate his premium amount. These premium calculators are free, handy, and easy to use.</p>
            <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/life2.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/life3.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/life4.jpg" alt="image">
                    </figure>
                </li>
            </ul>

            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>When you are buying insurance online you need to be double sure about everything. Grover Care is a reputed insurance aggregator that offers you the best quotes on life insurance plans from the most popular insurance companies. At Grover Care you can read about the features and benefits of a plan, compare it with other plans and apply for the same in the easiest way possible.</p>
            <blockquote class="wp-block-quote">
                <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> -->
                <p>I don’t call it “Life Insurance,” I call it “Love Insurance.” We buy it because we want to leave a legacy for those we love.</p>
                <cite>Tom Cruise</cite>
            </blockquote>
            
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Grover Care is connected with a number of life insurance companies and thus brings you the best of their plans. If you are someone planning to go for a life insurance plan then you can start by logging into Grover Care official website and navigating to the life insurance section. The website will present all the necessary information related to life insurance policies and will also showcase the best plans of the year. You can go through the page, compare plans as per your requirements and apply for the same within minutes.</p>
            <div class="truz-post-navigation">
                <div class="prev-link-wrapper">
                    <div class="info-prev-link-wrapper">
                        <a routerLink="/term-insurance">
                            <span class="image-prev">
                                <img src="assets/img/services-image/term4.jpg" alt="image">
                                <span class="post-nav-title">Prev</span>
                            </span>
                            <span class="prev-link-info-wrapper">
                                <span class="prev-title">Term Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="next-link-wrapper">
                    <div class="info-next-link-wrapper">
                        <a routerLink="/childcare-plans">
                            <span class="next-link-info-wrapper">
                                <span class="next-title">ChildCare Plans</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                            <span class="image-next">
                                <img src="assets/img/services-image/child4.jpg" alt="image">
                                <span class="post-nav-title">Next</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>