<app-header-one></app-header-one>

<div class="page-title-area page-title-termcondition">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Term & Conditions</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Term & Conditions</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-desc">
            <h3>Terms of Use</h3>
            <p>GroverCare Private Limited  welcomes you at ‘www.grovercare.com or mobile applications thereof accessible through various internet enabled smart devices (individually and collectively referred to as “Platform”) for providing access and choice to buy insurance products from various insurance companies, and related services and any offerings that are incidental and ancillary thereto (‘Services’), as available on the Platform. GroverCare is not an insurance company but a licensed Insurance Broking Company holding a Broking licence from the Insurance Regulatory and Development Authority of India.</p>
            <h3>Terms & Conditions of Term Insurance Plans</h3>
             <p>No matter how much an individual trusts his pilot, he would still want parachutes in his plane, in case of emergencies. While he will hope that he won't need it,nonetheless, we all know that it's wiser to have the parachutes on board. Simply put, it imparts confidence to the passengers that if things go wrong, they are protected.Term Insurance is like a parachute for the insured's life. Optimistically, one should never have to claim the same, but if the worst were to happen, at least one’s family will have a financial cushion to protect themselves from the financial consequences of his/her death.</p>
             <p>Claims in term insurance can be availed when the life insured passes away and his beneficiary claims the sum assured. After the internal formalities have been completed, the insurer pays the sum assured to the nominee as prescribed in the policy. Seems pretty simple, isn’t it?</p>
             <p>However, there are certain scenarios wherein things don’t work in this simple and straightforward manner and the insurer might deny your claims. This is why the insured and his/her nominees need to thoroughly understand the terms & conditions (read as exclusions) of the term insurance plan as given in the policy document.</p>
             <h3>Exclusions in an Accidental Death</h3>
             <p>Although accidental death is covered in a term insurance policy, there might be few cases wherein the insurance company might defer the claim. Usually, in case of an accidental death claim, every insurance company investigates the death from its end and generally has a designated team of healthcare professionals for the process.The investigation procedure might differ from one insurance provider to another one.</p>
             <h3>Exclusions Due to Lifestyle</h3>
             <p>Almost every insurance provider confirms the smoking habits of the insured beforehand. This piece of information is crucial as it comes in handy if the insured passes away due to a lifestyle issue.</p>
             <p>Simply put, smokers have lower mortality rates when compared to non-smokers and that’s why the latter are generally put in the high-risk category, which adversely impacts their premium rates, too.</p>
             <p>Thus, insurance providers have the right to deny the claim if the policyholder dies due to smoking and has failed to mention the same at the time of filling the proposal form.</p>
              <h3>In a Nutshell</h3>
              <p>In the end, not paying attention to the terms & conditions might lead to claim denial in the long run, which is not what you want. The whole point of insurance is to provide a sum assured to your loved ones to take care of their future. To lose this sum assured is unwise and counter-productive as you lose your premium amount as well.</p>
              <p>Therefore, it is really crucial that you understand the features and benefits of a term insurance plan thoroughly. Even though it’s a bit tedious to go through every mentioned exclusion (or inclusion) line by line, the effort put in the initial phase will help family members at the time of claim submission.</p>  
        </div>
    </div>
</section>


