<app-header-one></app-header-one>

<div class="page-title-area page-title-career">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Career</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Career</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">

        <div class="insurance-details-desc">
            <h3>Jobs at GroverCare</h3>
            <p>We are looking for Tigers to roar!</p>

              <p>If you believe you’re born to innovate great things. Well, you have come to the right place.</p>
            <h3>Why Us?</h3>
            <h4>Organization:</h4>
              <p>1. Largest online auto platform and only company providing end-to-end customer value proposition for automotive purchase</p>
                <p>2. Phenomenal growth story: Net Revenue grew 45% y-o-y in Q4FY21; Series E funded; recently entered the Unicorn club</p>

               <h4>Role:</h4>
              <p>1. Opportunity to make a real difference supplemented by great camaraderie, autonomy at work and super-fast speed to action</p>
                <p>2. Avenues to explore self-interests / strengths and land in the right role</p>

                <h4>Leadership:</h4>
                <p>1. Humble, Capable and visionary leadership</p>
                <p>2. Top-notch Advisory Board</p>
              
                <h4>Culture:</h4>
                <p>1. High on meritocracy: You hustle, you perform, you get rewarded</p>
                <p>2. Deep rooted values (DELITE – Decisive, Empathy, Lean, Innovative, Trustworthy, Excellent Customer Service)</p>
              
                <h3><u>View Openings</u></h3>
                <!-- <h3><u>View Openings</u></h3>

               <h4>Program manager-NCF Marketplace</h4>
                <p>Min. Exprience Required (3 years), No of positions (1), Location (Gurgoan)</p>

                <h4>Front End Engineer</h4>
                <p>Min. Exprience Required (3 years), No of positions (1), Location (Gurgoan)</p>

                <h4>Dot Net Developer / Sr. Developer</h4>
                <p>Min. Exprience Required (5years), No of positions (8), Location (Mumbai)</p>

                <h4>Java Developer</h4>
                <p>Min exp. required (5 years), No of positions (4), Location (Gurgoan)</p>

                <h4>Database Developer</h4>
                <p>Min exp. required (3 years), No of positions (1), Location (Pune)</p>

                <h4>Associate Sales Consultants </h4>
                <p>Outbound telesales of Insurance, Loans & credit cards Location - Gurgaon</p>
                   -->

                   
                   <div class="about-inner-area">
                    <div class="col">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="about-text-box">
                                <h3>Program manager-NCF Marketplace</h3>
                                <p>Min. Exprience Required (3 years), No of positions (1), Location (Gurgoan)</p>                        
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="about-text-box">
                                <h3>Business Manager</h3>
                                <p>Min. Exprience Required (2 years), No of positions (1), Location (Ludhiana)</p>                        
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="about-text-box">
                                <h3>Operation Cordinator</h3>
                                <p>Min. Exprience Required (1-3 years), No of positions (3), Location (Ludhiana)
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="about-text-box">
                                <h3>Associate Sales Consultants </h3>
                                <p>Min. Exprience Required (1-3 years), No of positions (2), Location (Ludhiana)
                                </p>
                            </div>
                        </div>
                       
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="about-text-box">
                                <h3>Technical Retail Associate</h3>
                                <p>Min. Exprience Required (2-5 years), No of positions (2), Location (Pune)
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="about-text-box">
                                <h3>Inspection Engineer</h3>
                                <p>Min. Exprience Required (2-3 years), No of positions (1), Location (Thane)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>



               
                
                    
    </div>
    </div>
</section>


