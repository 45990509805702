import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Constants } from "src/app/core/constant.service";
import { GenericCallService } from "src/app/core/generic-call.service";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    dataSource: any;

    constructor(
        private _genricCall: GenericCallService,
        private constant: Constants,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.getOrganizationList();
    }

    getOrganizationList() {
        this._genricCall
            .postMethodWithoutToken(
                this.constant.urlpaths().getOrganizationList,
                {}
            )
            .subscribe((data) => {
                console.log(data);
                // this.commonService.organizationList$.next(data);
                this.dataSource = data.body.data[0];
            });
    }
}
