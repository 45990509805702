import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Constants } from "src/app/core/constant.service";
import { GenericCallService } from "src/app/core/generic-call.service";

@Component({
    selector: "app-insurance-agent",
    templateUrl: "./insurance-agent.component.html",
    styleUrls: ["./insurance-agent.component.scss"],
})
export class InsuranceAgentComponent implements OnInit {
    AgentRequestForm: FormGroup;

    constructor(
        private _genricCall: GenericCallService,
        private constant: Constants,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.AgentRequestForm = new FormGroup({
            AgentRequestAID: new FormControl(0),
            Name: new FormControl("", [Validators.required]),
            Mobileno: new FormControl("", [Validators.required]),
            Email: new FormControl("", [Validators.required]),
            Experience: new FormControl("", [Validators.required]),
            IsActive: new FormControl(true),
        });
    }

    onSubmit(AgentRequestForm) {
        if (this.AgentRequestForm.valid) {
            this._genricCall
                .postMethodWithoutToken(
                    this.constant.urlpaths().AddAgentRequest,
                    AgentRequestForm.value
                )
                .subscribe((data) => {
                    console.log(data.body.data.isSuccess);
                    if (data.body.data.isSuccess == 1) {
                        this.toastr.success(
                            data.body.data.responseMessage,
                            "Success"
                        );
                        this.AgentRequestForm.reset();
                    } else {
                        this.toastr.warning(
                            data.body.data.responseMessage,
                            "Warning"
                        );
                    }
                });
        } else {
            const invalid = [];
            const controls = AgentRequestForm.controls;
            for (const name in controls) {
                if (controls[name].invalid) {
                    invalid.push(name);
                }
            }
            this.toastr.warning("Please fill " + invalid, "Warning");
        }
    }
}
