<app-header-one></app-header-one>

<div class="page-title-area page-title-grievance">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Grievance Form</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Grievance Form</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
        <div class="col-lg-8 col-md-8">
            <div class="tab quote-list-tab">
                <div class="tab_content">
                    <div class="tabs_item">
                        <p>Our experts will contact you soon</p>
                        <form  [formGroup]="ComplaintForm" (ngSubmit)="onSubmit(ComplaintForm)">
                            <div class="form-group">
                                <label class="form-label">Name of complainant</label>
                                <input type="text" class="form-control" placeholder="Name of the  complainant"  formControlName="ComplaineeName">
                                <div *ngIf="ComplaintForm.get('ComplaineeName').touched && ComplaintForm.get('ComplaineeName').invalid">
                                    <div *ngIf="this.ComplaintForm.get('ComplaineeName').hasError('required')" class="text-danger">This Field is Required</div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Name of the Firm : (If complaint being filed on behalf of Company/Organization)</label>
                                <input type="text" class="form-control" placeholder="Name of the  firm"  formControlName="FirmName">
                                <div *ngIf="ComplaintForm.get('FirmName').touched && ComplaintForm.get('FirmName').invalid">
                                    <div *ngIf="this.ComplaintForm.get('FirmName').hasError('required')" class="text-danger">This Field is Required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Address of the  complainant</label>
                                <input type="text" class="form-control" placeholder="Address of the  complainant"  formControlName="ComplaineeAddress">
                                <div *ngIf="ComplaintForm.get('ComplaineeAddress').touched && ComplaintForm.get('ComplaineeAddress').invalid">
                                    <div *ngIf="this.ComplaintForm.get('ComplaineeAddress').hasError('required')" class="text-danger">This Field is Required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Mobile Number / Telephone Number</label>
                                <input type="text" class="form-control" placeholder="Mobile Number"  formControlName="MobileNo">
                                <div *ngIf="ComplaintForm.get('MobileNo').touched && ComplaintForm.get('MobileNo').invalid">
                                    <div *ngIf="this.ComplaintForm.get('MobileNo').hasError('required')" class="text-danger">This Field is Required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Email Id</label>
                                <input type="email" class="form-control" placeholder="Email Id"  formControlName="Email">
                                <div *ngIf="ComplaintForm.get('Email').touched && ComplaintForm.get('Email').invalid">
                                    <div *ngIf="this.ComplaintForm.get('Email').hasError('required')" class="text-danger">This Field is Required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Insurance company</label>
                                <input type="text" class="form-control" placeholder="Insurance company"  formControlName="Insurancecompany">
                                <div *ngIf="ComplaintForm.get('Insurancecompany').touched && ComplaintForm.get('Insurancecompany').invalid">
                                    <div *ngIf="this.ComplaintForm.get('Insurancecompany').hasError('required')" class="text-danger">This Field is Required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Name of Intermediary/Agent</label>
                                <input type="text" class="form-control" placeholder="Name of Intermediary/Agent"  formControlName="AgentName">
                                <div *ngIf="ComplaintForm.get('AgentName').touched && ComplaintForm.get('AgentName').invalid">
                                    <div *ngIf="this.ComplaintForm.get('AgentName').hasError('required')" class="text-danger">This Field is Required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Policy number/Proposal number</label>
                                <input type="text" class="form-control" placeholder="Policy number/Proposal number"  formControlName="PolicyNumber">
                                <div *ngIf="ComplaintForm.get('PolicyNumber').touched && ComplaintForm.get('PolicyNumber').invalid">
                                    <div *ngIf="this.ComplaintForm.get('PolicyNumber').hasError('required')" class="text-danger">This Field is Required</div>
                                </div> 
                            </div>
                            <div class="form-group">
                                <label class="form-label">Certificate of Insurance Number (required for group insurance policy)</label>
                                <input type="text" class="form-control" placeholder="Certificate of Insurance Number"  formControlName="CertificateNumber">
                                <div *ngIf="ComplaintForm.get('CertificateNumber').touched && ComplaintForm.get('CertificateNumber').invalid">
                                    <div *ngIf="this.ComplaintForm.get('CertificateNumber').hasError('required')" class="text-danger">This Field is Required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Claim Number (mandatory for claim complaints)</label>
                                <input type="text" class="form-control" placeholder="Claim Number"  formControlName="ClaimNumber">
                                <div *ngIf="ComplaintForm.get('ClaimNumber').touched && ComplaintForm.get('ClaimNumber').invalid">
                                    <div *ngIf="this.ComplaintForm.get('ClaimNumber').hasError('required')" class="text-danger">This Field is Required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Details of Complaint</label>
                                <textarea class="form-control" placeholder="Details of complaint"  formControlName="DetailsofComplaint"></textarea>
                                <div *ngIf="ComplaintForm.get('DetailsofComplaint').touched && ComplaintForm.get('DetailsofComplaint').invalid">
                                    <div *ngIf="this.ComplaintForm.get('DetailsofComplaint').hasError('required')" class="text-danger">This Field is Required</div>
                                </div>
                            </div>


                            <div class="form-group">
                                <label class="form-label">Grievance Type</label>
                                <select  formControlName="NatureofComplaint">
                                    <option value="" disabled>Grievance Type</option>
                                    <option *ngFor="let data of dataSource"  value="data.attributeTypeId">{{data.attributeType }} </option>
                                </select>
                                <div *ngIf="ComplaintForm.get('NatureofComplaint').touched && ComplaintForm.get('NatureofComplaint').invalid">
                                    <div *ngIf="this.ComplaintForm.get('NatureofComplaint').hasError('required')" class="text-danger">This Field is Required</div>
                                </div>
                            </div>

                            <!-- <div class="form-group">
                                <label class="form-label">Nature of Non-Life Complaint</label>
                                <select  formControlName="NatureofComplaint">
                                    <option value="1" disabled>Policy Type</option>
                                    <option value="2">Unit Linked</option>
                                    <option value="2">Pension Policy</option>
                                    <option value="2">Health Insurance</option>
                                    <option value="2">Conventional Life</option>
                                    <option value="2">Others</option>
                                    <option value="1" disabled>Complain Type</option>
                                    <option value="2">Death Claims</option>
                                    <option value="2">Health(Incl. Critical illness Claims)</option>
                                    <option value="2">Health Insurance</option>
                                    <option value="2">Conventional Life</option>
                                    <option value="2">Others</option>
                                </select>
                            </div> -->


                           
                            <button type="submit" class="default-btn">Register Your Complain <span></span></button>
                        </form>
                    </div>




                </div>
            </div>
        </div>
    </div>
    </div>
</section>

