<app-header-one></app-header-one>

<div class="page-title-area page-title-grouphealth">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Group Health Insurance</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Group Health Insurance</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>Group Health policy is a multiple-line insurance policy</h3>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Randomised words which don't slightly believable.</p> -->
                        <p>Group health insurance provides coverage to a specific group of people such as employees of a company or members of an organization.Also known as a corporate health insurance policy, the insurance coverage under this health insurance policy usually includes accidental hospitalization.COVID insurance, daily hospital benefit, critical illness cover, maternity coverage, etc.</p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p> -->
                        <p>A group health insurance plan provides medical coverage for a group of people. It is usually purchased by companies or organizations to provide additional medical coverage to their employees as a perk because the premium amount of the group medical insurance plan is paid by the employer.</p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some for.</p> -->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="image text-center">
                        <img src="assets/img/services-image/grouphealth1.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="insurance-details-desc">
            <!-- <h3>Four major elements that we offer:</h3> -->
            <h3>Advantages of Group Health Insurance Policies</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
                <p>Employees are the treasured part of any organization. These days more and more companies are becoming employee-centric and corporate health insurance has emerged as one of the most preferred benefits to lure new talent.It plays a crucial role in influencing the psychology of an employee. It makes him feel part of a company and thus, it curtails employee attrition rate and unrest in labor unions. Moreover, it increases their productivity which increases profitability of a business. Special insurance schemes where families are also covered for benefits make employees faithful towards their employers. Moreover, by offering coverage to your employees, you become eligible to get tax deductions under Income Tax Act. So group or Corporate health insurance policy is a win-win situation for both employees and employers.</p>
                <p>Benefits of Group Health Insurance for Employers:</p>
                <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Helps in Employee Retention</li>
                        <li><i class="fas fa-check"></i>Tax Benefits</li>
                        <li><i class="fas fa-check"></i>Motivated Employees</li>
                        <li><i class="fas fa-check"></i>Better Benefits in Low Cost</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Pre-Existing Disease is Covered from Day 1</li>
                        <li><i class="fas fa-check"></i>An Extensive Range of Coverage with No Limit on Diseases</li>
                        <li><i class="fas fa-check"></i>Larger Maternity Coverage</li>
                        <li><i class="fas fa-check"></i>Free Medical Check-Up</li>
                    </ul>
                </div>
            </div>

            <h3>Want a Healthy Workforce? Employee Health Insurance can Help!</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>It is no secret that employees’ value benefits of group health insurance. However, as premium costs escalate and claim procedure being stringent; employee health insurance has become a tight rope walk in India. We at Grovercare introduce a wide gamut of group health insurance policy products to help you in choosing best corporate insurance plans that will suit both parties i.e. employers and employees. Blending the entire service right from expert advisory, purchase assistance and policy renewal in one package, Grovercare works towards making insurance process easy and convenient.</p>
            <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/grouphealth2.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/grouphealth3.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/grouphealth4.jpg" alt="image">
                    </figure>
                </li>
            </ul>

            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Also, once you decide which health insurance policy to buy, you can also add add-on covers to it for extensive coverage. It is not mandatory to buy add-on covers but you can include them in your policy voluntarily at the time of policy purchase, by paying an extra premium. Some popular add-ons offered under health insurance plans are room rent waiver, hospital daily cash, second e-opinion, critical illness cover, air ambulance cover, etc.</p>
            <blockquote class="wp-block-quote">
                <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> -->
                <p>Without health insurance,getting sick or injured could mean going bankrupt,going without care,or even dying needlessly.</p>
                <cite>Tom Cruise</cite>
            </blockquote>
            
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Buying group health insurance can be easy if you approach the right channel. Having said this, Grover Care can be a good platform for choosing the right insurance policy. Grover Care helps you to compare numerous health insurance plans and zero down on the plan that best fulfills your needs. Moreover, the post-sale services are extended to the customers online as well even at the time of medical insurance claim.</p>
            <div class="truz-post-navigation">
                <div class="prev-link-wrapper">
                    <div class="info-prev-link-wrapper">
                        <a routerLink="/home-insurance">
                            <span class="image-prev">
                                <img src="assets/img/services-image/home4.jpg" alt="image">
                                <span class="post-nav-title">Prev</span>
                            </span>
                            <span class="prev-link-info-wrapper">
                                <span class="prev-title">Home Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="next-link-wrapper">
                    <div class="info-next-link-wrapper">
                        <a routerLink="/travel-insurance">
                            <span class="next-link-info-wrapper">
                                <span class="next-title">Travel Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                            <span class="image-next">
                                <img src="assets/img/services-image/travel4.jpg" alt="image">
                                <span class="post-nav-title">Next</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
