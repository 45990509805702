<app-header-one></app-header-one>

<div class="page-title-area page-title-contact">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contact</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Message Us</span>
            <h2>Drop us Message for any Query</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <app-enquiry-form></app-enquiry-form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div id="map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13696.334249076534!2d75.8965837!3d30.8843233!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1f7206c77102da2d!2sGrover%20insurance%20Brokers%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1671701626942!5m2!1sen!2sin"></iframe>
                </div>
            </div>
        </div>

        <div class="contact-info">
            <div class="contact-info-content">
                <h3>Contact us by Phone Number or Email Address</h3>
                <h2>
                    <a href="tel:{{dataSource && dataSource.contactNo ?dataSource.contactNo:'#' }}">{{dataSource && dataSource.contactNo ?dataSource.contactNo:'#' }}</a>
                    <span>OR</span>
                    <a href="mailto:{{dataSource && dataSource.emailSer ? dataSource.emailSer : '#'}}">{{dataSource && dataSource.emailSer ? dataSource.emailSer : '#'}}</a>
                </h2>
                <ul class="social">
                    <!-- <li><a href="#" target="_blank" ><i class="fab fa-twitter"></i></a></li> -->
                    <li><a [href]="dataSource && dataSource.whatsapp ? dataSource.whatsapp : '#'" target="_blank" ><i class="fab fa-whatsapp"></i></a></li>
                    <li><a [href]="dataSource && dataSource.facebook ? dataSource.facebook : '#'" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a [href]="dataSource && dataSource.linkedin ? dataSource.linkedin : '#'" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a [href]="dataSource && dataSource.instagram ? dataSource.instagram : '#'" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</section>