<app-header-one></app-header-one>

<div class="home-area home-slides owl-carousel owl-theme">
    <!-- <div class="main-banner item-bg0">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        
                        <h1>OPENING SOON</h1>
                        <h2 style="color:white">We are coming soon in New Delhi</h2>
    
                        <div class="btn-box">
                            <a routerLink="/contact" class="default-btn">Get A Quote <span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="main-banner item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <!-- <span class="sub-title">Enjoy Your Happiness</span> -->
                        <h1>Execute Happiness By Incredible Opportunities</h1>
                        <p>Term insurance is also called a term life insurance plan, which provides high life cover at
                            low premium rates. A term plan offers financial coverage to the policyholder's family
                            against a fixed amount of premium for a specific period of time i.e. policy term.</p>

                        <!-- <div class="btn-box">
                            <a routerLink="/contact" class="default-btn">Get A Quote <span></span></a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner item-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content margin">
                        <!-- <span class="sub-title">Enjoy Your Happiness</span> -->
                        <h1>Traveling! How<br> Thrilling It Can Be</h1>
                        <p>Travel insurance protects you from any unexpected financial losses while travelling,
                            internationally or domestically.Basic travel insurance plans cover medical expenses,
                            however, comprehensive travel insurance policies also offer coverage for flight delays, lost
                            passports, trip cancellations, personal liability, etc.</p>

                        <!-- <div class="btn-box">
                            <a routerLink="/contact" class="optional-btn">Contact Us <span></span></a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner item-bg5">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content margin">
                        <!-- <span class="sub-title">Enjoy Your Happiness</span> -->
                        <h1>So You're Thinking of Moving, Enjoy the Effect</h1>
                        <p> Pension plans are otherwise called retirement plans. In this, you may put some segment of
                            your pay into the assigned plan. The principle objective behind a pension plan is to have a
                            normal salary post-retirement.</p>

                        <!-- <div class="btn-box">
                            <a routerLink="/contact" class="default-btn">Get A Quote <span></span></a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-box  ">

    <div class="container">
        <div class="row">
            <div class="col-sm-4 col-md-4 col-lg-2 ">
                <a routerLink="/term-insurance">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="fa fa-umbrella fa-3x fontsize"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h5>Term Insurance</h5>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </a>
            </div>
          
            <div class="col-sm-4 col-md-4 col-lg-2">
                <a routerLink="/health-insurance">
                    <div class="single-services-box">
                        <div class="icon">
                               <i class="fa fa-medkit fa-3x fontsize" ></i>
                               <div class="icon-bg">
                                <img src="assets/img/icon-bg1.png" alt="image">
                                <img src="assets/img/icon-bg2.png" alt="image">
                            </div>
                            <h5>Health Insurance</h5>

                            <div class="box-shape">
                                <img src="assets/img/box-shape1.png" alt="image">
                                <img src="assets/img/box-shape2.png" alt="image">
                            </div>
                            
                        </div>
                    </div>
                   
                </a>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-2">
                <a routerLink="/motor-insurance">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="fa fa-car fa-3x fontsize"></i>
                            <div class="icon-bg">
                                <img src="assets/img/icon-bg1.png" alt="image">
                                <img src="assets/img/icon-bg2.png" alt="image">
                            </div>
                            <h5>Car <br>Insurance</h5>

                            <div class="box-shape">
                                <img src="assets/img/box-shape1.png" alt="image">
                                <img src="assets/img/box-shape2.png" alt="image">
                            </div>
                        </div>
                    </div>
                   
                </a>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-2">
                <a routerLink="/motor-insurance">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="fa fa-motorcycle fa-3x fontsize"></i>
                            <div class="icon-bg">
                                <img src="assets/img/icon-bg1.png" alt="image">
                                <img src="assets/img/icon-bg2.png" alt="image">
                            </div>
                            <h5>2 Wheeler Insurance</h5>

                            <div class="box-shape">
                                <img src="assets/img/box-shape1.png" alt="image">
                                <img src="assets/img/box-shape2.png" alt="image">
                            </div>
                        </div>
                        </div>    
                </a>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-2">
                <a routerLink="/travel-insurance">
                    <div class="single-services-box">
                        <div class="icon">
                           <i class="fa fa-plane fa-3x fontsize"></i>
                           <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                        <h5>Travel Insurance</h5>

                        <div class="box-shape">
                            <img src="assets/img/box-shape1.png" alt="image">
                            <img src="assets/img/box-shape2.png" alt="image">
                        </div>
                            </div>
                        </div>
                </a>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-2">
                <a routerLink="/term-insurance">
                    <div class="single-services-box">
                        <div class="icon">
                           <i class="fas fa-coins fa-3x fontsize"></i>
                           <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                        <h5>Term Insurance</h5>

                        <div class="box-shape">
                            <img src="assets/img/box-shape1.png" alt="image">
                            <img src="assets/img/box-shape2.png" alt="image">
                        </div>
                           
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-sm-4 col-md-4 col-lg-2">
                <a routerLink="/home-insurance">
                    <div class="single-services-box">
                        <div class="icon">

                            <i class="fa fa-home fa-3x fontsize"></i>
                            <div class="icon-bg">
                                <img src="assets/img/icon-bg1.png" alt="image">
                                <img src="assets/img/icon-bg2.png" alt="image">
                            </div>
                            <h5>Home Insurance</h5>
    
                            <div class="box-shape">
                                <img src="assets/img/box-shape1.png" alt="image">
                                <img src="assets/img/box-shape2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-2">
                <a routerLink="/life-insurance">
                    <div class="single-services-box">
                        <div class="icon">

                           <i class="fa-sharp fa-solid fa-wave-pulse"></i><i
                                        class="fa fa-heartbeat fa-3x fontsize"></i>
                                        <div class="icon-bg">
                                            <img src="assets/img/icon-bg1.png" alt="image">
                                            <img src="assets/img/icon-bg2.png" alt="image">
                                        </div>
                                        <h5>Life br Insurance</h5>
                
                                        <div class="box-shape">
                                            <img src="assets/img/box-shape1.png" alt="image">
                                            <img src="assets/img/box-shape2.png" alt="image">
                                        </div>
                        
                    </div>
                    </div>
                  
                </a>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-2">
                <a routerLink="/individual-health-insurance">
                    <div class="single-services-box">
                        <div class="icon">

                            <i class="fa-solid fa-family"></i><i
                                        class="fa fa-person fa-3x fontsize"></i>
                                        <div class="icon-bg">
                                            <img src="assets/img/icon-bg1.png" alt="image">
                                            <img src="assets/img/icon-bg2.png" alt="image">
                                        </div>
                                        <h5>Individual  Insurance</h5>
                
                                        <div class="box-shape">
                                            <img src="assets/img/box-shape1.png" alt="image">
                                            <img src="assets/img/box-shape2.png" alt="image">
                                        </div>
                        </div>
                    </div>
                   
                </a>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-2">
                <a routerLink="/group-health-insurance">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="fa fa-people-group fa-3x fontsize"></i>
                            <div class="icon-bg">
                                <img src="assets/img/icon-bg1.png" alt="image">
                                <img src="assets/img/icon-bg2.png" alt="image">
                            </div>
                            <h5>Group Health Insurance</h5>
    
                            <div class="box-shape">
                                <img src="assets/img/box-shape1.png" alt="image">
                                <img src="assets/img/box-shape2.png" alt="image">
                            </div>

                            
                        </div>
                    </div>
                   
                </a>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-2">
                <a routerLink="/retirement-plans">
                    <div class="single-services-box">
                        <div class="icon">
                            <i class="fa-solid fa-person-cane fa-3x fontsize"></i>
                            <div class="icon-bg">
                                <img src="assets/img/icon-bg1.png" alt="image">
                                <img src="assets/img/icon-bg2.png" alt="image">
                            </div>
                            <h5>Retirement Plans</h5>
    
                            <div class="box-shape">
                                <img src="assets/img/box-shape1.png" alt="image">
                                <img src="assets/img/box-shape2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-2">
                <a routerLink="/childcare-plans">
                    <div class="single-services-box">

                        <div class="icon">
                           <i class="fa-solid fa-hands-holding-child fa-3x fontsize"></i>
                           <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                        <h5>ChildCare Plans</h5>

                        <div class="box-shape">
                            <img src="assets/img/box-shape1.png" alt="image">
                            <img src="assets/img/box-shape2.png" alt="image">
                        </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>



</section>








<section class="services-boxes-area bg-f8f8f8">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="image">
                        <img src="assets/img/featured-services-image/img1.jpg" alt="grover-care-featured-service">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/home-insurance">Trustworthy Company</a></h3>
                        <p>Fill in your details and get insurance policy premium quotes from top-rated insurers
                            instantly.</p>

                        <div class="icon">
                            <img src="assets/img/icon1.png" alt="grover-care-featured-service">
                        </div>
                        <div class="shape">
                            <img src="assets/img/umbrella.png" alt="grover-care-umbrella-protection">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="image">
                        <img src="assets/img/featured-services-image/img2.jpg" alt="grover-care-featured-service">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/home-insurance">100% Digital Platform</a></h3>
                        <p>Pay online and get your policy right away in your inbox.</p>

                        <div class="icon">
                            <img src="assets/img/icon2.png" alt="grover-care-digital-platform">
                        </div>
                        <div class="shape">
                            <img src="assets/img/umbrella.png" alt="grover-care-featured-service">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-box">
                    <div class="image">
                        <img src="assets/img/featured-services-image/img3.jpg" alt="grover-care-any-time-collection">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/home-insurance">Anytime Cancellation</a></h3>
                        <p>In case of a cancellation, the premium paid for an insurance product will be refunded.</p>

                        <div class="icon">
                            <img src="assets/img/icon3.png" alt="grover-care-featured-service">
                        </div>
                        <div class="shape">
                            <img src="assets/img/umbrella.png" alt="grover-care-featured-service">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-area bg-f8f8f8 pb-70">
    <div class="container">
        <div class="partner-title mt-3">
            <h1>Insurance Services</h1>
        </div>
        <div class="services-slides owl-carousel owl-theme">
            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-car-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="grover-care-background">
                        <img src="assets/img/icon-bg2.png" alt="grover-care-background-2">
                    </div>
                </div>

                <h3><a routerLink="/general-insurance">General Insurance</a></h3>
                <!-- <p>Motor insurance is a contract between you and the insurance company. It protects you from financial loss if there's an accident or theft.</p> -->
                <p>A General insurance policy is a non-life insurance product that includes a range of general insurance
                    policies. </p>
                <a routerLink="/general-insurance" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="grover-care-general-insurance">
                    <img src="assets/img/box-shape2.png" alt="grover-care-featured-service">
                </div>
            </div>


            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-home-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/home-insurance">Home Insurance</a></h3>
                <p>Homeowners insurance is a form of property insurance that covers losses and damages to an
                    individual's house and assets in the home.</p>
                <a routerLink="/home-insurance" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>

          
            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-health-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/health-insurance">Health Insurance</a></h3>
                <p> Health insurance is a contract that requires an insurer to pay some or all of a person's healthcare
                    costs in exchange for a premium.</p>
                <a routerLink="/health-insurance" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>

            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-travel-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/travel-insurance">Travel Insurance</a></h3>
                <p>Travel insurance is designed to provide financial protection for unexpected events that impact a
                    traveler’s trip.</p>
                <a routerLink="/travel-insurance" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>

            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-car-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/motor-insurance">Motor Insurance</a></h3>
                <p>Motor insurance is a contract between you and the insurance company. It protects you from financial
                    loss if there's an accident or theft.</p>
                <a routerLink="/motor-insurance" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>




            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-life-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/term-insurance">Term Insurance</a></h3>
                <p>Term insurance is the simplest and purest form of life insurance. It provides financial protection to
                    your family at the most affordable rates.</p>
                <!-- <p>Motor insurance is a contract between you and the insurance company. It protects you from financial loss if there's an accident or theft.</p> -->
                <a routerLink="/term-insurance" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>



            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-life-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/life-insurance">Life Insurance</a></h3>
                <p>Life insurance is a protection against financial loss that would result from the premature death of
                    an insured.</p>
                <a routerLink="/life-insurance" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>


            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-car-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/childcare-plans">ChildCare Plans</a></h3>
                <p>A child plan is a mix of investment and insurance that helps in the financial planning for a kid's
                    future needs. </p>
                <a routerLink="/childcare-plans" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>

            <div class="single-services-box">
                <div class="icon">
                    <i class="flaticon-car-insurance"></i>

                    <div class="icon-bg">
                        <img src="assets/img/icon-bg1.png" alt="image">
                        <img src="assets/img/icon-bg2.png" alt="image">
                    </div>
                </div>

                <h3><a routerLink="/retirement-plans">Retirement Plans</a></h3>
                <!-- <p>Motor insurance is a contract between you and the insurance company. It protects you from financial loss if there's an accident or theft.</p> -->
                <p>A General insurance policy is a non-life insurance product that includes a range of general insurance
                    policies. </p>
                <a routerLink="/retirement-plans" class="read-more-btn">Learn More</a>

                <div class="box-shape">
                    <img src="assets/img/box-shape1.png" alt="image">
                    <img src="assets/img/box-shape2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-area">
    <div class="container">
        <div class="partner-title">
            <h1>Our Trusted Partners</h1>
        </div>

        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/lic.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/bharti.jpg" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/birla-sun-line.jpg" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/hdfc_life.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/idbi.jpg" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/oriental.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/national.jpg" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="why-choose-us-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="why-choose-us-slides owl-carousel owl-theme">
                    <div class="why-choose-us-image bg1">
                        <img src="assets/img/why-choose-img1.jpg" alt="image">
                    </div>

                    <div class="why-choose-us-image bg2">
                        <img src="assets/img/why-choose-img2.jpg" alt="image">
                    </div>

                    <div class="why-choose-us-image bg3">
                        <img src="assets/img/why-choose-img3.jpg" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <div class="title">
                            <span class="sub-title">Your Benefits</span>
                            <h1>Why Choose Us</h1>
                            <p>We are committed to bring our wealth of knowledge to every individual, every corporate,
                                and every business to safeguard their future and ensure their growth. Our ingenious team
                                of experts offer advice to both individuals and corporates. For individuals, we have
                                solutions to help protect their life, health, motor. And for Corporates, right from
                                insuring their assets, offices, factories, employees, supply chain, digital footprint,
                                legal liabilities, financial uncertainties, our experts ensure peace of mind every step
                                of the way.</p>
                        </div>

                        <ul class="features-list">
                            <li>
                                <div class="icon">
                                    <i class="flaticon-like"></i>
                                </div>
                                <span>59 Years of Experience</span>
                                Our leadership brings together an energetic group of people with a wealth of experience
                                and commitment towards helping people make better financial decisions.
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-customer-service"></i>
                                </div>
                                <span>24/7 Support</span>
                                At the same time, customers who have bought their policies from Grover Care
                                can reach out to their insurance experts to seek assistance.
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-care"></i>
                                </div>
                                <span>Service with Love</span>
                                Grover Care An Insurance Broker Guarantees claim support for your family in those
                                difficult times.
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-team"></i>
                                </div>
                                <span>Clients Focused</span>
                                We always focused on clients requirements and provide them best solutions.
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-policy"></i>
                                </div>
                                <span>No Policy Fees</span>
                                No extra policy fees are charged by us.
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-education"></i>
                                </div>
                                <span>Growing Your Business</span>
                                Today, we are India’s best & largest online insurance marketplace.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="quote-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="quote-content">
                    <h1>Get A Free Quote</h1>
                    <p>From numerous available quotes, choose the one that best suits your requirements and budget.</p>

                    <div class="image">
                        <img src="assets/img/grovercare-quote.jpg" alt="grovercare-quote">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="tab quote-list-tab">
                    <app-enquiry-form></app-enquiry-form>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ctr-area">
    <div class="container">
        <div class="ctr-content">
            <h1>Insurances For Your Child's Future</h1>
            <p>A child plan is a mix of investment and insurance that helps in the financial planning for a kid's future
                needs.</p>
            <a routerLink="/contact" class="default-btn">Get A Quote <i
                    class="flaticon-right-chevron"></i><span></span></a>
        </div>

        <div class="ctr-image">
            <img src="assets/img/ctr-img.jpg" alt="image">
        </div>

        <div class="shape">
            <img src="assets/img/bg-dot3.png" alt="image">
        </div>
    </div>
</section>

<section class="achievements-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="achievements-content">
                    <div class="title">
                        <span class="sub-title">Number</span>
                        <h2>Our Achievements</h2>
                        <p>It’s a fine balancing act when 9 million+ insurance buyers and more than 50+ insurers
                            transact a few lac crores of insurance cover and a few thousand crores of insurance premium
                            on a platform with complete confidence every year.</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-6 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-flag"></i>
                                <h3><span class="odometer" data-count="65">00</span></h3>
                                <p>Countries</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-6 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-group"></i>
                                <h3><span class="odometer" data-count="107">00</span> <span class="sign-icon">m</span>
                                </h3>
                                <p>Clients</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-medal"></i>
                                <h3><span class="odometer" data-count="150">00</span></h3>
                                <p>Wining Awards</p>
                            </div>
                        </div>
                    </div>
                    <div class="bg-dot"><img src="assets/img/bg-dot.png" alt="image"></div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="achievements-image-slides owl-carousel owl-theme">
                    <div class="single-achievements-image bg1">
                        <img src="assets/img/achievements-img1.jpg" alt="image">
                    </div>
                    <div class="single-achievements-image bg2">
                        <img src="assets/img/achievements-img2.jpg" alt="image">
                    </div>
                    <div class="single-achievements-image bg3">
                        <img src="assets/img/achievements-img3.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="blog-area ptb-100 pb-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Blog</span>
            <h2>News And Insights</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img1.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Oct 14, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">2020 Insurance Trends And Possible Challenges</a></h3>
                        <p>Insurance companies and businesses play a vital role in reviving economies. But the global health crisis and the increased volatility of the market have put immense pressure on the industry.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img2.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Oct 10, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">Global Trends in the Life Insurance Industry</a></h3>
                        <p>Globally, the share of life insurance business in total premium was 54.30% and the share of non-life insurance premium was 45.70%, in 2018.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img3.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Sep 13, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">The Best Car Insurance Companies in 2020</a></h3>
                        <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="blog-notes">
                    <p>Insights to help you do what you do better, faster and more profitably. <a routerLink="/blog-grid">Read Full Blog</a></p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="join-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="join-image text-center">
                    <img src="assets/img/woman.png" alt="image">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="join-content">
                    <h2>Great Place to Work</h2>

                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p> -->
                    <a routerLink="/agent" class="default-btn">Join Now <i
                            class="flaticon-right-chevron"></i><span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>