import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Constants } from "src/app/core/constant.service";
import { GenericCallService } from "src/app/core/generic-call.service";

@Component({
    selector: "app-grievance-form",
    templateUrl: "./grievance-form.component.html",
    styleUrls: ["./grievance-form.component.scss"],
})
export class GrievanceFormComponent implements OnInit {
    ComplaintForm: FormGroup;
    dataSource: any = [];

    constructor(
        private _genricCall: GenericCallService,
        private constant: Constants,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.getAttributeMstList();

        this.ComplaintForm = new FormGroup({
            GrievanceAid: new FormControl(0),
            ComplaineeName: new FormControl("", [Validators.required]),
            FirmName: new FormControl("", [Validators.required]),
            ComplaineeAddress: new FormControl("", [Validators.required]),
            MobileNo: new FormControl("", [Validators.required]),
            Email: new FormControl("", Validators.required),
            Insurancecompany: new FormControl("", [Validators.required]),
            AgentName: new FormControl("", [Validators.required]),
            PolicyNumber: new FormControl("", [Validators.required]),
            CertificateNumber: new FormControl("", [Validators.required]),
            ClaimNumber: new FormControl("", [Validators.required]),
            DetailsofComplaint: new FormControl("", [Validators.required]),
            NatureofComplaint: new FormControl("", [Validators.required]),
            ActionFlag: new FormControl(""),
            IsActive: new FormControl(true),
        });
    }

    getAttributeMstList() {
        debugger;
        this._genricCall
            .postMethodWithoutToken(
                this.constant.urlpaths().GetAttributeMst,
                {}
            )
            .subscribe((res) => {
                if (res && res.body.data) {
                    this.dataSource = res.body.data;
                }
            });
    }

    //   getAttributeTypeList(Id: number) {
    //     this._genricCall
    //         .postMethodWithoutToken(
    //             this.constants.urlpaths().getAttributeListById + Id,
    //             {}
    //         )
    //         .subscribe((response) => {
    //
    //             if (response && response.body.data) {
    //                 this.policyTypes = response.body.data;
    //             }
    //         });
    // }

    onSubmit(ComplaintForm: FormGroup) {
        debugger;
        console.log(ComplaintForm.value);

        if (this.ComplaintForm.valid) {
            this._genricCall
                .postMethodWithoutToken(
                    this.constant.urlpaths().AddGrievanceForm,
                    ComplaintForm.value
                )
                .subscribe((data) => {
                    console.log(data.body.data.isSuccess);
                    if (data.body.data.isSuccess == 1) {
                        this.toastr.success(
                            data.body.data.responseMessage,
                            "Success"
                        );
                    } else {
                        this.toastr.warning(
                            data.body.data.responseMessage,
                            "Warning"
                        );
                    }
                });
        } else {
            const invalid = [];
            const controls = ComplaintForm.controls;
            for (const name in controls) {
                if (controls[name].invalid) {
                    invalid.push(name);
                }
            }
            this.toastr.warning("Please fill " + invalid, "Warning");
        }
    }
}
