<app-header-one></app-header-one>

<div class="page-title-area page-title-child">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>ChildCare Plan</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>ChildCare Plan</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>What is ChildCare Plane?</h3>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Randomised words which don't slightly believable.</p> -->
                        <p>A child plan is a mix of investment and insurance that helps in the financial planning for a kid's future needs. The insurance aspect ensures that a child remains protected in the event of the unfortunate demise of a parent. The investment avenue allows you to create a sufficient corpus to secure your child’s future. More importantly, child plans come with flexible payouts at crucial milestones that can effectively fund a kid’s education at different stages. </p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p> -->
                        <p>Insurance for children should not be overlooked given the uncertainty of life. Children are dependent on adults to feed them and finance their educational pursuits, among other needs. On the death of a parent, a child should not be made to struggle for funds to survive and access the basic level of care and education. This is why insurance for children is a must if you are a parent.</p>
                        <!-- <p>An insurer under the health insurance policy provides coverage for hospitalisation, health check-ups, ambulance charges, etc., in accordance with the amount insured by the policyholder.</p> -->
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some for.</p> -->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="image text-center">
                        <img src="assets/img/services-image/child1.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="insurance-details-desc">
            <!-- <h3>Four major elements that we offer:</h3> -->
            <h3>Key Features and Benefits of a Child Education Plan</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
                <p>The purchase of insurance for children offers a wide  range of exciting and unique benefits to the policyholder. It offers a comprehensive maturity benefit along with life cover to financially secure the future of the child. </p>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Corpus for Child's Education</li>
                        <li><i class="fas fa-check"></i>High Returns Beating Inflation</li>
                        <li><i class="fas fa-check"></i>A Kitty for Medical Treatment of the Child</li>
                        <li><i class="fas fa-check"></i>Supports the Child in the Absence of Parent(s)</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Income Protection for the Child</li>
                        <li><i class="fas fa-check"></i>Acts as Collateral for Loans for Higher Education</li>
                        <li><i class="fas fa-check"></i>Partial Withdrawal to Enhance your Child’s Talent</li>
                        <li><i class="fas fa-check"></i>Tax Benefits</li>
                    </ul>
                </div>
            </div>

            <h3>Setting the mood with incense</h3>
            <!-- <h3>How to Get the Best Child Education Plan</h3> -->
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>There are many child plans offered by insurance providers; however, certain things should be considered while choosing the best child plan to ensure the best future for your child. Below-mentioned tips help in making a wise decision to best meet the child's needs.Most child plans offer maturity benefit and start giving payouts at key milestones in life after the child turns 18 years old. The overall benefit of the best child education plan is higher if one starts investing early.</p>
            <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/child2.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/child3.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/child4.jpg" alt="image">
                    </figure>
                </li>
            </ul>

            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Child plans usually invest funds collected from policyholders in capital markets to earn a higher return. However, they offer the insured or policyholder, the choice to choose the type of fund to invest their money in depending on individual investment appetite and risk-taking ability.Those who are risk-averse may want their funds to be allocated in debt, which offers more stability against market volatility. People who want to earn a higher return on investment may be fine with their investment being put into equity.</p>
            <blockquote class="wp-block-quote">
                <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> -->
                <p>kid’s grow up quickly and so do their needs start investing today.</p>
                <cite>Tom Cruise</cite>
            </blockquote>
            
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>At Grover Care, we take pride in helping parents like you to ensure a bright future for your children. Every child is unique and so are his insurance needs. Who knows, your children might turn out to be future Einstein or Tendulkar. Make sure you financially equip your child to tap the opportunity when it knocks.There are a number of options for you to look at when it comes to saving for your child’s secure future. The following table compares three different types of savings avenues. </p>
            <div class="truz-post-navigation">
                <div class="prev-link-wrapper">
                    <div class="info-prev-link-wrapper">
                        <a routerLink="/life-insurance">
                            <span class="image-prev">
                                <img src="assets/img/services-image/life4.jpg" alt="image">
                                <span class="post-nav-title">Prev</span>
                            </span>
                            <span class="prev-link-info-wrapper">
                                <span class="prev-title">Life Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="next-link-wrapper">
                    <div class="info-next-link-wrapper">
                        <a routerLink="/retirement-plans">
                            <span class="next-link-info-wrapper">
                                <span class="next-title">Retirement Plan</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                            <span class="image-next">
                                <img src="assets/img/services-image/retirement4.jpg" alt="image">
                                <span class="post-nav-title">Next</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
