<div class="form-body">
    <div *ngIf="showSuccessMessage"><h6>{{SuccessMessage}}</h6></div>
    <form class="row g-3" [formGroup]="form" (ngSubmit)="onSubmit(form)">
       
            <div class="form-group" *ngIf="defaultInsuranceType==0">
                <label>Insurance Type</label>
                <select class="form-select"  formControlName="InsuranceType" placeholder="Select Insurance Type">
                    <option data-display="Select Insurance Type" disabled selected>Select Insurance Type</option>
                    <option *ngFor="let data of policyTypes" value="{{ data.attributeId }}">
                        {{ data.attribute }}
                    </option>
                </select>
            </div>
            <app-form-validation-message-component [control]="form.controls['InsuranceType']"
                [validations]="validationMessages['InsuranceType']">
            </app-form-validation-message-component>
     
        <div class="form-group">
            <label>Name</label>
            <input type="text" formControlName='Name' class="form-control" placeholder="Your Name">
        </div>
        <app-form-validation-message-component [control]="form.controls['Name']"
            [validations]="validationMessages['Name']">
        </app-form-validation-message-component>
        <div class="form-group" *ngIf="showDOBField">
            <label>Date of Birth</label>
            <input type="date" formControlName='DOB' class="form-control" placeholder="Your Date of Birth">
        </div>
        
            <app-form-validation-message-component [control]="form.controls['DOB']" [validations]="validationMessages['DOB']">
        </app-form-validation-message-component>
        
        <div class="form-group">
            <label>Email</label>
            <input type="email" formControlName='EmailId' class="form-control" placeholder="Your Email">
        </div>
        <app-form-validation-message-component [control]="form.controls['EmailId']"
            [validations]="validationMessages['EmailId']">
        </app-form-validation-message-component>

        <div class="form-group">
            <label >Mobile Number</label>
            <input type="text" formControlName='ContactNo' class="form-control" placeholder="Your Phone">
        </div>
        <app-form-validation-message-component [control]="form.controls['ContactNo']"
            [validations]="validationMessages['ContactNo']">
        </app-form-validation-message-component>
       
        <div class="form-group">
            <label>City</label>
            <input type="text" formControlName='City' class="form-control" placeholder="Your City">
        </div>
        <app-form-validation-message-component [control]="form.controls['City']"
                                               [validations]="validationMessages['City']">
        </app-form-validation-message-component>
        <div class="form-group">
            <button type="submit" class="default-btn">Get A Free Quote<span></span></button>
        </div>
    </form>
</div>