<app-header-one></app-header-one>

<div class="page-title-area page-title-becomeagent">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h2>Become an Agent</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Become an Agent</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="insurance-details-area ptb-100">
  <div class="container">
    <div class="insurance-details-header">



      <div class="row">
        <div class="col-4">

          <div class="about-text-box mt-5">
            <h3 class="text-content">Become an Agent</h3>
          </div>
        </div>


        <div class="col-md-8">

          <div class="insurance-details-desc">
            <ul class="features-list">

              <li><span>1.&nbsp;India's largest Insurance company backs you</span> </li>
              <li><span>2.&nbsp;Huge potential in the market due to under-insurance</span></li>
              <li><span>4.&nbsp;Unlimited earning opportunities</span></li>
              <li><span>5.&nbsp;Help people insure</span></li>
            </ul>
          </div>




        </div>

      </div>
















      <!-- <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>Become an Agent</h3>
                        <ul class="features-list">
                                        <li><h5>India's largest Insurance company backs you</h5></li>
                                        <li><h5>India's largest Insurance company backs you</h5></li>
                                        <li><h5>Unlimited earning opportunities</h5></li>
                                        <li><h5>Unlimited earning opportunities</h5></li>                
                       </ul>
                    </div>
                </div>

          
            </div> -->
    </div>


    <section class="about-inner-area">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Enjoy the Reward</h5>
              <p class="card-text">As an agent,you can earn well and enjoy good rewards.</p>
              <div class="row">
                <div class="col-md-4">
                  <div class="about-text-box">
                    <h3>Sales Incentives</h3>
                    <p>First commission</p>
                    <p>Renewable commission</p>
                    <p>Bonus commission</p>
                    <p>Hereditary commission</p>
                    <p>Competition Prizes </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="about-text-box">
                    <h3>Special Benefits</h3>
                    <p>Special Benefits</p>
                    <p>Term Insurance</p>
                    <p>Group Insurance</p>
                    <p>Medical Insurance</p>
                    <p>Group Personal Accident & Disability Scheme </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="about-text-box">
                    <h3>Allowances & Advances</h3>
                    <p>Office Allowance</p>
                    <p>Fringe Benefits</p>
                    <p>Laptop/Computer</p>
                    <p>Housing Loan at concessional rates</p>
                    <p> Purchase of 2/4 wheelers </p>
                  </div>
                </div>
              </div>
              <p class="card-text">*Condition Apply</p>
            </div>
          </div>
        </div>



      </div>

    </section>


    <section class="start-up">
      <div class="row">
        <div class="col-sm-12 mt-5">
          <div class="card">
            <div class="card-body">


              <div class="row">
                <div class="col-md-4">
                  <div class="d-flex flex-column justify-content-center">
                    <div class="about-text-box mt-5">
                      <h3 class="text-content">Start Up in 5 Easy Way</h3>
                    </div>
                  </div>
                </div>

                <div class="col-md-8">

                  <div class="insurance-details-desc">
                    <ul class="features-list">

                      <li><span>1.&nbsp;Fill in application form</span> </li>
                      <li><span>2.&nbsp;Submit necessary documents like:- <br>
                          <span>Photo, Age Proof, Address Proof, Qualification Certificate</span></span></li>
                      <li><span>3.&nbsp;Undergo preparatory training for appearing in the Insurance Agency
                          Examination</span></li>
                      <li><span>4.&nbsp;Pass the Examination</span></li>
                      <li><span>5.&nbsp;Receive Letter of Appointment from us and become an Agent</span></li>
                    </ul>
                  </div>




                </div>

              </div>








            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="faq">
      <div class="row">
        <div class="col-sm-12 mt-5">
          <div class="card">
            <div class="card-body">


              <div class="row">


                <div class="col-md-12">


                  <div class="container">
                    <div class="faq-main">
                      <div class="titleTxt">
                        <h2><span>FAQs</span></h2>
                      </div>
                      <div class="faqAccord">
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button class="accordion-button collapsed" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="false">
                                <h6> How much salary can I expect as an Agent?</h6>
                              </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <p>There is no prescribed salary for an Agent. You can get unlimited commission earnings
                                  and other benefits.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button class="accordion-button collapsed" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="false">
                                <h6>How much can I earn as an Agent?</h6>
                              </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <p>Unlimited Earnings, which is in direct proportion to the business you bring in.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button class="accordion-button collapsed" data-bs-toggle="collapse"
                                data-bs-target="#collapseThree" aria-expanded="false">
                                <h6>What are the work timings?</h6>
                              </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <p>Since you are your own boss, you decide your work timings.</p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button class="accordion-button collapsed" data-bs-toggle="collapse"
                                data-bs-target="#collapseFour" aria-expanded="false">
                                <h6>Do I need to take an exam to become an Agent?</h6>
                              </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <p>Yes. You have to pass the examination conducted by the Insurance Institute of India.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button class="accordion-button collapse" data-bs-toggle="collapse"
                                data-bs-target="#collapseFive" aria-expanded="false">
                                <h6>What is the eligibility criteria to become an Agent?</h6>
                              </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse"
                              data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <p>You should have cleared the 10th standard examination.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Section>
      <button id="buyBtn" (click)="openAgent()">Apply Now</button>
    </Section>
    <section>
    </section>
  </div>
</section>