<app-header-one></app-header-one>

<div class="page-title-area page-title-claims">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Claims</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Claims</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">

        <div class="insurance-details-desc">
            <h3>Do you Need Insurance Claim Assistance?</h3>
            <p>Don't worry, we are here to help you.</p>
            <h3>How It Works?</h3>
            <p>Step 1 :Compare and Buy a Term Plan on GroverCare Website.</p>
            <p>Step 2 :Speak to our expert advisors for guidance on your requirement.</p>
            <p>Step 3 :Buy the best-suited plan as per your needs.</p>
            <p>Step 4:Congratulations! You’re enrolled for Dedicated Claims Assistance Program!</p>
               
            <h3>Documents Required For Term Insurance Claim Submission</h3>
            <p>Thoroughly filled claim form ( provided by the insurer)</p>
            <p>Medical records (admission notes, death/ discharge summary, test report, etc.)</p>
            <p>Photo of the nominee, ID proof like PAN card, Aadhar card, passport, etc.</p>
            <p>Original policy documents</p>
            <p>Death certificate (original and attested copy issued by local municipal authority</p>
            <p>Post-mortem report, if any</p>
                    
    </div>
    </div>
</section>



