<app-header-one></app-header-one>

<div class="page-title-area page-title-image1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>General Insurance</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>General Insurance</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>General policy is a multiple-line insurance policy</h3>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Randomised words which don't slightly believable.</p> -->
                        <p>A General insurance policy is a non-life insurance product that includes a range of general insurance policies.Common forms of general insurance in India are automobiles, mediclaim, homeowner’s insurance, marine, travel, and others. The policy offers payment to the policyholder based on the loss incurred from a specific financial event. General insurance is insurance that is not categorized under life insurance.</p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p> -->
                        <p>Unlike life insurance policies, the tenure of general insurance policies is normally not that of a lifetime. The usual term lasts for the duration of a particular economic activity or a given period. Most general insurance products are annual contracts. There are, however, a few products, which have a long term.</p>
                        <p>The concept of insurance applies to a large group of people that may suffer the same issue in the same region or condition. The money that is collected as the premium can be considered as a pool and when one encounters some loss, he/she is paid from this pool.</p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some for.</p> -->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="image text-center">
                        <img src="assets/img/services-image/general.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="insurance-details-desc">
            <!-- <h3>Four major elements that we offer:</h3> -->
            <h3>Types of General Insurance Policies:</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
                <p>The classification is quite simple to understand. Life Insurance is the insurance plan you buy to insure your life. You get the amount you paid as the premium in case of your untimely or unexpected demise.Any other insurance plan you buy apart from Life Insurance comes under General Insurance. By definition, General Insurance is the type of insurance that covers any kind of loss except death.</p>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Motor Insurance</li>
                        <li><i class="fas fa-check"></i>Health Insurance</li>
                        <li><i class="fas fa-check"></i>Travel Insurance</li>
                        <li><i class="fas fa-check"></i>Home Insurance</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Marine Insurance</li>
                        <li><i class="fas fa-check"></i>Rural Insurance</li>
                        <li><i class="fas fa-check"></i>Mobile Insurance</li>
                        <li><i class="fas fa-check"></i>Commercial Insurance</li>
                    </ul>
                </div>
            </div>

            <h3>The Concept of General Insurance</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>Still, confused about what is a general insurance policy and general insurance meaning? If yes, then go through the following example, suppose your family member suffered a heart attack and he/she requires a transplant on an immediate basis. At the same time, you have to pay the fees of your daughter's first year of college. Now you have huge expenses to meet consecutively and both the issues have the same priority. In this stressful time, the health insurance policy for your family can share your burden and you can pay the fees of your daughter from your savings without any worry. In this way, general insurance in India works as your savior. </p>
            <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/general1.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/general2.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/general3.jpg" alt="image">
                    </figure>
                </li>
            </ul>

            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Suppose you are driving back home after completing a stressful day at work and suddenly another car hits your car from behind. Your car's bumper comes out and it gets a dent. Now you require approximately Rs.7500 to fix the bumper and Rs.2000 for dent repair. A car insurance plan in such a case plays a key role. The amount that you have paid for repairing your car can be reimbursed under a comprehensive car insurance policy. In this way, a general insurance policy for a car fulfilled your requirement of financial help in case of car damage due to an accident.</p>
            <blockquote class="wp-block-quote">
                <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> -->
                <p>Those who love to their life and health,they get secure them by insurance.</p>
                <cite>Tom Cruise</cite>
            </blockquote>
            
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Premiums are not very high for the general insurance plans in India. Most of the plans can be bought for below Rs. 10, 000 and any damage incurred to you would cost more than that. Even though in most of the cases, the insurance policy may not cover the entire damage or loss, but it can offset and reduce the burden to your pocket.The Indian General Insurance industry is ranked 18th among 88 nations, which is healthy and is dominated by public sector companies.Motor and Health insurance comprise nearly 70% of the Indian insurance industry.</p>
            <div class="truz-post-navigation">
                <div class="prev-link-wrapper">
                    <div class="info-prev-link-wrapper">
                        <a routerLink="/retirement-plans">
                            <span class="image-prev">
                                <img src="assets/img/services-image/retirement4.jpg" alt="image">
                                <span class="post-nav-title">Prev</span>
                            </span>
                            <span class="prev-link-info-wrapper">
                                <span class="prev-title">Retirement Plan</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="next-link-wrapper">
                    <div class="info-next-link-wrapper">
                        <a routerLink="/home-insurance">
                            <span class="next-link-info-wrapper">
                                <span class="next-title">Home Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                            <span class="image-next">
                                <img src="assets/img/services-image/home4.jpg" alt="image">
                                <span class="post-nav-title">Next</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
