<app-header-one></app-header-one>

<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-area ptb-100 bg-f8f8f8">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-image/about1.jpg" alt="image">
                    <img src="assets/img/about-image/about2.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2>"Bharosa Service Ka"</h2>                   
                    <p>Grover Group has been at the forefront of serving Ludhiana population and bringing the best to it’s customers. With {{noOfYearExp}} years of experience in Logistics as DS Transport and then entering automobile industry in 2008 as Grover Motors Pvt Ltd with Ashok Leyland and then with Hyundai as Grover Automobiles Pvt Ltd in 2015;the group has entered into the insurance sector in 2022.</p>
                    <p><strong>Grover Care</strong> is one stop solution to all your insurance needs- health, motor, fire, marine, transport… We firmly believe in maintaining transparency with customers and working with single most important philosophy of customer satisfaction.</p>
                     <p>Grover Care is a pioneer in laying down systems for smooth and orderly conduct of the business. The strength of the Company lies in its highly trained and motivated work force that covers various disciplines and has vast expertise. Grover Care specializes in devising special covers for large projects. The Company provide various types of insurance covers to cater to the needs of both the urban and rural population of India. The Company has a technically qualified and competent team of professionals to render the best customer service.</p>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="about-text-box">
                        <h3>Our History</h3>
                        <p>Grover care is pioneer start up of Grover group. this group belongs to logistics and automobiles industry from 1958.</p>                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="about-text-box">
                        <h3>Our Mission</h3>
                        <p>To safeguard all Indian citizen by providing them insurance solution for their life, health and belongings</p>                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="about-text-box">
                        <h3>24X7 Support</h3>
                        <p>At the same time, customers who have bought their policies from Grover Care can reach out to their insurance
                            experts to
                            seek assistance.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="about-text-box">
                        <h3>Our Commitment</h3>
                        <p>We are committed to bring our wealth of knowledge to every individual, every corporate, and every business to safeguard
                        their future and ensure their growth.
                        </p>
                    </div>
                </div>
               
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="about-text-box">
                        <h3>Service Commitment</h3>
                        <p>We always focused on clients requirements and provide them best solutions. Grover Care committed to your claim support for your family in those difficult times.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="about-text-box">
                        <h3>Our Solutions</h3>
                        <p>Grover Care is one stop solution to all your insurance needs- Life,Health, General, Motor, Terms, etc. We firmly believe
                        in maintaining transparency with customers.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area ptb-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-home-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/home-insurance">Home Insurance</a></h3>
                    <p>Homeowners insurance is a form of property insurance that covers losses and damages to an individual's house and assets in the home.</p>
                    <a routerLink="/home-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-health-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/health-insurance">Health Insurance</a></h3>
                    <p> Health insurance is a contract that requires an insurer to pay some or all of a person's healthcare costs in exchange for a premium.</p>
                    <a routerLink="/health-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-travel-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/travel-insurance">Travel Insurance</a></h3>
                    <p>Travel insurance is designed to provide financial protection for unexpected events that impact a traveler’s trip.</p>
                    <a routerLink="/travel-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-car-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/motor-insurance">Motor Insurance</a></h3>
                    <p>Motor insurance is a contract between you and the insurance company. It protects you from financial loss if there's an accident or theft.</p>
                    <a routerLink="/motor-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-life-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/life-insurance">Life Insurance</a></h3>
                    <p>Life insurance is a protection against financial loss that would result from the premature death of an insured.</p>
                    <a routerLink="/life-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-life-insurance"></i>

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/term-insurance">Term Insurance</a></h3>
                    <p>Term insurance is the simplest and purest form of life insurance. It provides financial protection to your family at the most affordable rates.</p>
                    <a routerLink="/term-insurance" class="read-more-btn">Learn More</a>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="partner-area">
    <div class="container">
        <div class="partner-title">
            <h2>Our Trusted Partners</h2>
        </div>

        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/lic.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/bharti.jpg" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/birla-sun-line.jpg" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/hdfc_life.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/idbi.jpg" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/oriental.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/national.jpg" alt="image">
                </a>
            </div>

        </div>
    </div>
</section>