// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // apiUrl: "http://gcareapi.grovercare.com/api/",
    // apiUrl: "https://localhost:44370/api/",
    versionCheckURL: "assets/version.json",

    localOrigin: "http://localhost:4200",
    localApiUrl: "http://localhost:44370/api/",
    localDomainUrl: "http://localhost:4200",
    localBackOfficeUrl: "http://localhost:3001/",

    devOrigin: "http://dev.grovercare.com/",
    devApiUrl: "http://gcareapi.dev.grovercare.com/api/",
    devDomainUrl: "http://dev.grovercare.com/",
    devBackOfficeUrl: "http://backoffice.dev.grovercare.com/",

    qaOrigin: "http://qa.grovercare.com/",
    qaApiUrl: "http://gcareapi.qa.grovercare.com/api/",
    qaDomainUrl: "http://qa.grovercare.com/",
    qaBackOfficeUrl: "http://backoffice.qa.grovercare.com/",

    prodOrigin: "http://grovercare.com/",
    prodApiUrl: "http://gcareapi.grovercare.com/api/",
    prodDomainUrl: "http://grovercare.com/",
    prodBackOfficeUrl: "http://backoffice.grovercare.com/",

    prodSecureOrigin: "https://grovercare.com/",
    prodSecureApiUrl: "https://gcareapi.grovercare.com/api/",
    prodSecureDomainUrl: "https://grovercare.com/",
    prodSecureBackOfficeUrl: "https://backoffice.grovercare.com/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
