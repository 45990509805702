<app-header-one></app-header-one>

<div class="page-title-area page-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="color:black">My Profile</h2>
                    <ul>
                        <li><a routerLink="/" style="color:black">Home</a></li>
                        <li style="color:black">Grievance Form</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
        <div class="col-lg-8 col-md-8">
            <div class="tab quote-list-tab">
                <div class="tab_content">
                    <div class="tabs_item">
                        


                        <div class="row">
                            <div class="col-sm-6">
                              <div class="card">
                                <div class="card-body">

                                        <div class="row">
                                            <div class="col-md-10">
                                                <h5 class="card-title">
                                                    Male
                                                </h5>
                                            </div>
                                            <div class="col-md-2">
                                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                                            </div>     
                                        </div>

                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="card">
                                <div class="card-body">
                                 
                                    <div class="row">
                                        <div class="col-md-10">
                                            <h5 class="card-title">
                                                Female
                                            </h5>
                                        </div>
                                        <div class="col-md-2">
                                            <input class="form-check-input" type="radio"  name="exampleRadios" id="success" value="option2">
                                        </div>     
                                    </div>

                                </div>
                              </div>
                            </div>
                          </div>





                        <form>
                            <div class="form-group">
                                <label class="form-label">Full Name</label>
                                <input type="text" class="form-control" placeholder="Full Name"  formControlName="CustName">
                                <!-- <div *ngIf="ComplaintForm.get('ComplaineeName').touched && ComplaintForm.get('ComplaineeName').invalid">
                                    <div *ngIf="this.ComplaintForm.get('ComplaineeName').hasError('required')" class="text-danger">This Field is Required</div>
                                </div>-->
                            </div>

                            <div class="form-group">
                                <label class="form-label">Date of Birth(MM/DD/YYYY)</label>
                                <input type="date" class="form-control" placeholder="Name of the  firm"  formControlName="DateOfBirth">
                                <!-- <div *ngIf="ComplaintForm.get('Nameoffirm').touched && ComplaintForm.get('Nameoffirm').invalid">
                                    <div *ngIf="this.ComplaintForm.get('Nameoffirm').hasError('required')" class="text-danger">This Field is Required</div>
                                </div> -->
                            </div>
                            <div class="form-group">
                                <label class="form-label">Marital Status</label>
                                <select formControlName="MaritalStatus">
                                    <option value="" disabled>Marital Status</option>
                                    <option>Single</option>
                                    </select>
                                <!-- <div *ngIf="ComplaintForm.get('ComplaineeAddress').touched && ComplaintForm.get('ComplaineeAddress').invalid">
                                    <div *ngIf="this.ComplaintForm.get('ComplaineeAddress').hasError('required')" class="text-danger">This Field is Required</div>
                                </div> -->
                            </div>


                            <div class="form-group">
                                <label class="form-label">Mobile Number / Telephone Number</label>
                                <input type="text" class="form-control" placeholder="Mobile Number"  formControlName="MobileNo">
                                <!-- <div *ngIf="ComplaintForm.get('MobileNo').touched && ComplaintForm.get('MobileNo').invalid">
                                    <div *ngIf="this.ComplaintForm.get('MobileNo').hasError('required')" class="text-danger">This Field is Required</div>
                                </div> -->
                            </div>
                            <div class="form-group">
                                <label class="form-label">Email Id</label>
                                <input type="email" class="form-control" placeholder="Email Id"  formControlName="Email">
                                <!-- <div *ngIf="ComplaintForm.get('Email').touched && ComplaintForm.get('Email').invalid">
                                    <div *ngIf="this.ComplaintForm.get('Email').hasError('required')" class="text-danger">This Field is Required</div>
                                </div> -->
                            </div>
                            <div class="form-group">
                                <label class="form-label">Annual Income</label>
                                <select>
                                    <option value="" disabled>Annual Income</option>
                                    <option>3-5 lakhs</option>
                                    </select>



                                <!-- <div *ngIf="ComplaintForm.get('Insurancecompany').touched && ComplaintForm.get('Insurancecompany').invalid">
                                    <div *ngIf="this.ComplaintForm.get('Insurancecompany').hasError('required')" class="text-danger">This Field is Required</div>
                                </div> -->
                            </div>
                            <div class="form-group">
                                <label class="form-label">City</label>
                                <input type="text" class="form-control" placeholder="City">
                                <!-- <div *ngIf="ComplaintForm.get('AgentName').touched && ComplaintForm.get('AgentName').invalid">
                                    <div *ngIf="this.ComplaintForm.get('AgentName').hasError('required')" class="text-danger">This Field is Required</div>
                                </div> -->
                            </div>
                            

                            <button type="submit" class="default-btn">Save<span></span></button>
                        </form>
                    </div>




                </div>
            </div>
        </div>
    </div>
    </div>
</section>

