<app-header-one></app-header-one>
<div class="page-title-area height-200">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
              
            </div>
        </div>
    </div>
</div>
<div class="container ptb-100 text-center">
    <!-- <div class="row"> -->
        <a routerLink="/term-insurance">
        <div class="single-services-box d-table-cell text-center">
            <div class="icon">
                <i class="fa fa-circle-check fa-3x fontsize"></i>

                <div class="icon-bg">
                    <img src="assets/img/icon-bg1.png" alt="image">
                    <img src="assets/img/icon-bg2.png" alt="image">
                </div>
            </div>

            <h2>Thank You!</h2>
            <p>Your submission has been sent.</p>
            <p>Our represtentative contact you soon.</p>

            <div class="box-shape">
                <img src="assets/img/box-shape1.png" alt="image">
                <img src="assets/img/box-shape2.png" alt="image">
            </div> 
        </div>
 </a> 
    <!-- </div> -->
</div>
