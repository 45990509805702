import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { SessionCheck } from "./session/sessioncheck.service";
import { CommonService } from './common.service';


@Injectable({
    providedIn: "root",
})
export class GenericCallService {
    _host = "";
    _url = "";
    prm: any = [];

    constructor(
        private _Http: HttpClient,
        private _commonService: CommonService,
        private _sessioncheck: SessionCheck
    ) {
        this._host = _commonService.apiUrl;
    }

    postMethodWithoutToken(sp: any, param: any): Observable<any> {
        this._url = this._host + sp;
        const headers = { "Content-Type": "application/json" };
        return this._Http
            .post<any>(this._url, param, {
                headers: headers,
                observe: "response",
            })
            .pipe(
                map((resp) => {
                    return resp;
                })
            );
    }

    postMethodWithToken(sp: any, token: any, data: any): Observable<any> {
        try {
            this._url = this._host + sp + "/json";
            const headers = {
                "Content-Type": "application/json",
                Token: token,
            };
            return this._Http
                .post<any>(this._url, data, {
                    headers: headers,
                    observe: "response",
                })
                .pipe(
                    map((resp) => {
                        return resp.body.ResultSets;
                    }),
                    catchError((error) =>
                        this.handleError(error, this._sessioncheck, "")
                    )
                );
        } catch (error) {
            this._sessioncheck.logout();
        }
    }

    handleError(error, _scall, _notification) {
        const _ = this;
        try {
            let errorMessage = "";
            const err = error;
            if (error instanceof ErrorEvent) {
                // client-side error
                errorMessage = `Error: ${error.message}`;
            } else {
                // server-side error
                errorMessage = `Error Code: ${error.status}\nMessage: ${err.Message}`;
                const statusCode = error.status;
                if (error.name === "TypeError") {
                    errorMessage = error.name + errorMessage;
                }

                if (statusCode === "401" || statusCode === 401) {
                    _notification.error(
                        "Your session is expired. Please login again.",
                        "Session expired"
                    );
                    setTimeout(() => {
                        _scall.logout();
                    }, 1000);
                }
            }
            return throwError(error);
        } catch (error) {
            _scall.logout();
            return error;
        }
    }
}
