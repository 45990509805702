import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { HeaderOneComponent } from "./components/layouts/header-one/header-one.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { TestimonialsComponent } from "./components/pages/testimonials/testimonials.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { HomeInsuranceComponent } from "./components/pages/home-insurance/home-insurance.component";
import { BusinessInsuranceComponent } from "./components/pages/business-insurance/business-insurance.component";
import { HealthInsuranceComponent } from "./components/pages/health-insurance/health-insurance.component";
import { TravelInsuranceComponent } from "./components/pages/travel-insurance/travel-insurance.component";
import { CarInsuranceComponent } from "./components/pages/car-insurance/car-insurance.component";
import { LifeInsuranceComponent } from "./components/pages/life-insurance/life-insurance.component";
import { CaseStudyComponent } from "./components/pages/case-study/case-study.component";
import { CaseStudyDetailsComponent } from "./components/pages/case-study-details/case-study-details.component";
import { EventsDetailsComponent } from "./components/pages/events-details/events-details.component";
import { EventsComponent } from "./components/pages/events/events.component";
import { ServicesOneComponent } from "./components/pages/services-one/services-one.component";
import { ServicesTwoComponent } from "./components/pages/services-two/services-two.component";
import { BlogGridComponent } from "./components/pages/blog-grid/blog-grid.component";
import { BlogRightSidebarComponent } from "./components/pages/blog-right-sidebar/blog-right-sidebar.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { SharedModule } from "./core/shared.module";
import { QuoteFormComponent } from "./components/pages/quote-form/quote-form.component";
import { TermInsuranceComponent } from "./components/pages/term-insurance/term-insurance.component";
import { GeneralInsuranceComponent } from "./components/pages/general-insurance/general-insurance.component";
import { RetirementPlansComponent } from "./components/pages/retirement-plans/retirement-plans.component";
import { ChildcarePlansComponent } from "./components/pages/childcare-plans/childcare-plans.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { TermConditionsComponent } from "./components/pages/term-conditions/term-conditions.component";
import { CareerComponent } from "./components/pages/career/career.component";
import { OurSolutionsComponent } from "./components/pages/our-solutions/our-solutions.component";
import { ClaimsComponent } from "./components/pages/claims/claims.component";
import { GrouphealthInsuranceComponent } from "./components/pages/grouphealth-insurance/grouphealth-insurance.component";
import { IndividualhealthInsuranceComponent } from "./components/pages/individualhealth-insurance/individualhealth-insurance.component";
import { GrievanceFormComponent } from "./components/pages/grievance-form/grievance-form.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MyProfileComponent } from "./components/pages/my-profile/my-profile.component";
import { MyPoliciesComponent } from "./components/pages/my-policies/my-policies.component";
import { InsuranceAgentComponent } from "./components/pages/insurance-agent/insurance-agent.component";
import { EnquiryFormComponent } from "./components/pages/shared/components/enquiry-form/enquiry-form.component";
import { AgentDetailsComponent } from "./components/pages/agent-details/agent-details.component";
import { FormValidationMessageComponentComponent } from "./components/pages/shared/components/form-validation-message-component/form-validation-message-component.component";
import { EnquiryComponent } from "./components/pages/enquiry/enquiry.component";
import { ThankYouComponent } from './components/pages/shared/components/thank-you/thank-you.component';
import { MotorInsuranceLandingComponent } from './components/pages/motor-insurance-landing/motor-insurance-landing.component';

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        HomeOneComponent,
        FooterComponent,
        HeaderOneComponent,
        AboutComponent,
        TeamComponent,
        PricingComponent,
        TestimonialsComponent,
        FaqComponent,
        ErrorComponent,
        ContactComponent,
        HomeInsuranceComponent,
        BusinessInsuranceComponent,
        HealthInsuranceComponent,
        TravelInsuranceComponent,
        CarInsuranceComponent,
        LifeInsuranceComponent,
        CaseStudyComponent,
        CaseStudyDetailsComponent,
        EventsDetailsComponent,
        EventsComponent,
        ServicesOneComponent,
        ServicesTwoComponent,
        BlogGridComponent,
        BlogRightSidebarComponent,
        BlogDetailsComponent,
        QuoteFormComponent,
        TermInsuranceComponent,
        GeneralInsuranceComponent,
        RetirementPlansComponent,
        ChildcarePlansComponent,
        PrivacyPolicyComponent,
        TermConditionsComponent,
        CareerComponent,
        OurSolutionsComponent,
        ClaimsComponent,
        GrouphealthInsuranceComponent,
        IndividualhealthInsuranceComponent,
        GrievanceFormComponent,
        MyProfileComponent,
        MyPoliciesComponent,
        InsuranceAgentComponent,
        EnquiryFormComponent,
        AgentDetailsComponent,
        FormValidationMessageComponentComponent,
        EnquiryComponent,
        ThankYouComponent,
        MotorInsuranceLandingComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        SharedModule,
        ReactiveFormsModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
