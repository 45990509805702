<section class="quote-area">
    <div class="container">
        <div style="text-align: center;
    margin-bottom: 10px;">
        <div class="truz-responsive-menu">
            <div class="logo">
                <a routerLink="/"><img src="assets/img/gc-logo.png" alt="logo" style="width:300px;"></a>              
            </div>
        </div>
        </div>
        <div class="section-title">
            <h1>Enquiry Form</h1>
        </div>
        <div class="row pb-70">            
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="tab quote-list-tab" style="width: 500px; margin:auto;">
                    <app-enquiry-form></app-enquiry-form>
                </div>
            </div>
        </div>
    </div>
</section>