import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { SecurityCheck } from '../security/securitycheck.service';
import { environment } from '../../../../src/environments/environment';
import { VersionCheckService } from '../version/version-check.service';
import { VersionControlling } from '../version/versionControlling.service';

@Injectable({
  providedIn: 'root',
})
export class SessionCheck {
  constructor(
    public router: Router,
    protected _securityCheck: SecurityCheck,
    private versionControlling: VersionControlling,
    private _versionCheckService: VersionCheckService
  ) { }

  session_Check(name: string) {
    localStorage.getItem('userid') === null ||
      localStorage.getItem('loggedtype') === null ||
      localStorage.getItem('token') == null ||
      localStorage.getItem('name') == null ||
      localStorage.getItem('email') == null
      ? name === 'Admin'
        ? this.clearAllSession()
        : this.clearAllSession()
      : this.getLocalStorage('loggedtype') !== name
        ? name === 'Admin'
          ? this.clearAllSession()
          : this.clearAllSession()
        : '';
  }

  session_Check_by_Name(name: any) {
    return localStorage.getItem(name) == null ? false : true;
  }

  getLocalStorage(name: any) {
    this._versionCheckService.initVersionCheck(environment.versionCheckURL);
    return localStorage.getItem(name) == null ||
      localStorage.getItem(name) === ''
      ? ''
      : this._securityCheck.Decrypt(localStorage.getItem(name));
  }

  setLocalStorage(id: any, name: any) {
    localStorage.setItem(id, this._securityCheck.Encrypt(name));
  }

  clearSession(name: any) {
    localStorage.removeItem(name);
  }

  clearAllSession() {
    localStorage.clear();
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/public/sign-in']);
  }

  logoutValidation() {
    localStorage.clear();
    this.router.navigate(['/public/sign-in']);
  }

  session_CheckWithQuery(name: string, query: string) {
    localStorage.getItem('userid') == null ||
      localStorage.getItem('loggedtype') == null ||
      localStorage.getItem('token') == null ||
      localStorage.getItem('name') == null ||
      localStorage.getItem('email') == null
      ? name === 'Admin'
        ? this.clearAllSessionWithQuery('Admin', query)
        : this.clearAllSessionWithQuery('other', query)
      : this.getLocalStorage('loggedtype') !== name
        ? name === 'Admin'
          ? this.clearAllSessionWithQuery('Admin', query)
          : this.clearAllSessionWithQuery('other', query)
        : '';
  }

  clearAllSessionWithQuery(name: any, query: string) {
    localStorage.clear();
    this.router.navigate(['/public/sign-in']);
  }
}
