import { Component, OnInit } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CommonService } from "src/app/core/common.service";
import { Constants } from "src/app/core/constant.service";
import { FirmDetailService } from "src/app/core/firm-detail.service";
import { GenericCallService } from "src/app/core/generic-call.service";
import { SessionCheck } from "src/app/core/session/sessioncheck.service";

@Component({
    selector: "app-header-one",
    templateUrl: "./header-one.component.html",
    styleUrls: ["./header-one.component.scss"],
})
export class HeaderOneComponent implements OnInit {
    userLogin$: Observable<boolean>;
    firmDetail: any;
    dataSource: any;
    constructor(
        public _commonService: CommonService,
        private _sessionCall: SessionCheck,
        public _firmDetail: FirmDetailService,
        private _genricCall: GenericCallService,
        private constant: Constants,
    ) {}

    ngOnInit(): void {
        //this.userLogin$ = this._commonService.userLogin$;
        this.userLogin$ =
            this._sessionCall.getLocalStorage("token") &&
            this._sessionCall.getLocalStorage("userID")
                ? new BehaviorSubject(true)
                : new BehaviorSubject(false);
        this.firmDetail = this._firmDetail.getOrganizationDetial();
        this.getOrganizationList()
    }
    logout() {
        this._commonService.userLogin$.next(false);
        this._sessionCall.clearAllSession();
    }
    onlogin() {
        this._commonService.signupModal$.next("signin");
    }
    openQuote() {
        this._commonService.signupModal$.next("quote");
    }

    getOrganizationList() {
        this._genricCall
            .postMethodWithoutToken(
                this.constant.urlpaths().getOrganizationList,
                {}
            )
            .subscribe((data) => {
                console.log(data);
                // this.commonService.organizationList$.next(data);
                this.dataSource = data.body.data[0];
            });
    }




}
