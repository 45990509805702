import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { AuthService } from "src/app/core/auth/auth.service";
import { CommonService } from "src/app/core/common.service";
import { Constants } from "src/app/core/constant.service";
import { GenericCallService } from "src/app/core/generic-call.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
    loginForm: FormGroup;
    signupModalShow$: Observable<string>;
    dataSource: any;
    constructor(
        private genericService: GenericCallService,
        private authService: AuthService,
        private router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private commonService: CommonService,
        private constant: Constants
    ) {}
    redirectToBackoffice() {
        this.router.navigate([]).then((result) => {
            window.open(this.commonService.backOfficeUrl, "_blank");
        });
    }
    // On ForgotPassword link click
    onForgotPassword() {
        this.router.navigate(["forgot-password"], {
            relativeTo: this.route.parent,
        });
    }

    ngOnInit(): void {
        this.signupModalShow$ = this.commonService.signupModal$;
        this.loginForm = this.fb.group(
            {
                userName: new FormControl(null, [Validators.required]),
                password: new FormControl(null, [
                    Validators.required,
                    Validators.minLength(4),
                    Validators.maxLength(24),
                ]),
            },
            { updateOn: "blur" }
        );

        this.getOrganizationList();
    }

    // // On Sing-in click
    // onSubmit() {
    //
    //     if (this.loginForm.valid) {
    //         this.authService.signInUser(
    //             this.loginForm.value.userName,
    //             this.loginForm.value.password
    //         );
    //     }
    // }

    // // On Signup link click
    // onSignup() {
    //     this.router.navigate(["sign-up"], { relativeTo: this.route.parent });
    // }

    getOrganizationList() {
        this.genericService
            .postMethodWithoutToken(
                this.constant.urlpaths().getOrganizationList,
                {}
            )
            .subscribe((data) => {
                // this.commonService.organizationList$.next(data);
                this.dataSource = data.body.data[0];
                console.log(this.dataSource);
            });
    }
}
