import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { BehaviorSubject, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { SessionCheck } from "./session/sessioncheck.service";

@Injectable({
    providedIn: "root",
})
export class CommonService {
    signupModal$ = new BehaviorSubject("signin");
    userLogin$ = new BehaviorSubject(false);
    origin = window.location.origin + "/";
    apiUrl: string;
    domainUrl: string;
    backOfficeUrl: string;

    UserId$ = new BehaviorSubject(null);
    TokenKey$ = new BehaviorSubject(null);
    constructor(private _sessionCall: SessionCheck) {
        switch (this.origin) {
            case environment.localOrigin: {
                this.apiUrl = environment.localApiUrl;
                this.domainUrl = environment.localDomainUrl;
                this.backOfficeUrl = environment.localBackOfficeUrl;
                break;
            }
            case environment.devOrigin: {
                this.apiUrl = environment.devApiUrl;
                this.domainUrl = environment.devDomainUrl;
                this.backOfficeUrl = environment.devBackOfficeUrl;
                break;
            }
            case environment.qaOrigin: {
                this.apiUrl = environment.qaApiUrl;
                this.domainUrl = environment.qaDomainUrl;
                this.backOfficeUrl = environment.qaBackOfficeUrl;
                break;
            }
            default: {
                this.apiUrl = environment.prodSecureApiUrl;
                this.domainUrl = environment.prodSecureDomainUrl;
                this.backOfficeUrl = environment.prodSecureBackOfficeUrl;
                break;
            }
        }

        this.UserId$ = this._sessionCall.getLocalStorage("userID")
            ? this._sessionCall.getLocalStorage("userID")
            : null;
        this.TokenKey$ = this._sessionCall.getLocalStorage("token")
            ? this._sessionCall.getLocalStorage("token")
            : null;
        this.userLogin$ =
            this._sessionCall.getLocalStorage("token") &&
            this._sessionCall.getLocalStorage("userID")
                ? new BehaviorSubject(true)
                : new BehaviorSubject(false);
    }

    getValidationMessages(formControls: any, validationEnumMess: any) {
        const validationMessages = {};
        for (const formControlName in formControls) {
            if (
                Object.prototype.hasOwnProperty.call(
                    formControls,
                    formControlName
                )
            ) {
                for (const key in validationEnumMess) {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            validationEnumMess,
                            key
                        )
                    ) {
                        const validationMessage = validationEnumMess[key];
                        const controlName = key.split("-")[0];
                        const validationType = key.split("-")[1];

                        if (controlName === formControlName) {
                            if (!validationMessages[controlName]) {
                                validationMessages[controlName] = [];
                            }
                            validationMessages[controlName].push({
                                ValidationType: validationType,
                                ValidationMessage: validationMessage,
                            });
                        }
                    }
                }
            }
        }
        return validationMessages;
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }
}
