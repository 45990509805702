<div class="sidebar-about-area">
    <div class="title">
    </div>
</div>
<div class="bg-login">
    <!--wrapper-->
    <div class="wrapper">
        <div class="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
            <div class="container">
                <div class="row">
                    <div class="col mx-auto">
                        <!-- <div class="mb-4 text-center">
                                <img src="assets/images/logo-img.png" width="180" alt="" />
                            </div> -->
                        <div class="card">
                            <div class="card-body">
                                <div class="border p-4 rounded">
                                    <div class="text-center">
                                        <h3 class="">Log in</h3>
                                        <!-- <p>Don't have an account yet? <a href="JavaScript:;" (click)="onSignup()">Sign up here</a>
                                            </p> -->
                                    </div>

                                    <div class="form-body">
                                        <ng-container *ngIf="!OTPsend;else OtpSent">
                                            <form class="row g-3" [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                                                <div class="col-12">
                                                    <label for="inputChoosePassword" class="form-label">Mobile
                                                        No.</label>
                                                    <div class="input-group" id="show_hide_password">
                                                        <input type="text" class="form-control border-end-0 "
                                                            id="inputChoosePassword" formControlName="mobileno"
                                                            placeholder="Mobile No. ">
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-6">
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked>
                                                        <label class="form-check-label" for="flexSwitchCheckChecked">Remember Me</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 text-end">	<a href="JavaScript:;" (click)="onForgotPassword()">Forgot Password ?</a>
                                                </div> -->
                                                <div class="col-12">
                                                    <div class="d-grid">

                                                        <!-- <button  type="submit" class="default-btn" >
                                                                <i [ngClass]="(this.showLoader$ | async) ? 'disabled fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom':'bx bxs-lock-open'"></i>
                                                                Log in</button> -->
                                                        <!-- <h5>{{this.showLoader}}</h5> -->
                                                        <!-- <h5>{{this.commonService.showLoader}}</h5>  -->
                                                        <button type="submit" class="default-btn"
                                                            [disabled]="this.showLoader">
                                                            <i
                                                                [ngClass]="this.showLoader ? 'disabled spinner-border' : 'bx bxs-lock-open'"></i>
                                                            Sign in</button>
                                                        <!-- <button type="submit" class="default-btn">
                                                            <i [ngClass]="showLoader ? 'disabled fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom':'bx bxs-lock-open' "></i>Sign
                                                            in</button> -->
                                                    </div>
                                                </div>
                                            </form>
                                        </ng-container>
                                        <ng-template #OtpSent>
                                            <form class="row g-3" [formGroup]="loginForm" (ngSubmit)="onOTPSubmit()">
                                                <div *ngIf="message">{{message}}</div>
                                                <div class="col-12">
                                                    <label for="inputChoosePassword" class="form-label">
                                                        OTP</label>
                                                    <div class="input-group" id="show_hide_password">
                                                        <input class="form-control border-end-0 " maxlength="6"
                                                            minlength="6" id="inputChoosePassword" formControlName="otp"
                                                            placeholder="Enter 6 digit OTP">
                                                        <div *ngIf="onFormSubmit &&  loginForm.controls.otp.errors">
                                                            OTP is required
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="d-grid">
                                                        <!-- <button type="submit" class="default-btn">
                                                            <i
                                                                [ngClass]="(showLoader$ | async)  ? 'disabled fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom':'bx bxs-lock-open'"></i>
                                                            Verify</button> -->
                                                        <button type="submit" class="default-btn"
                                                            [disabled]="this.showLoader">
                                                            <i
                                                                [ngClass]="this.showLoader ? 'disabled spinner-border' : 'bx bxs-lock-open'"></i>
                                                            Verify</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end row-->
            </div>


        </div>
    </div>
    <!--end wrapper-->
</div>

<div class="sidebar-contact-area">
    <div class="sidebar-contact-info">
        <div class="contact-info-content">
            <p>First time user?<a href="#" (click)="onSignup()"> Sign up</a></p>
            <h2>
                <!-- <a href="tel:08146490900">+91 81464-90900</a> -->
                <a href="tel:07087020244">+91 70870-20244</a>
                <span>OR</span>
                <a href="mailto:info@grovercare.com">info@grovercare.com</a>
            </h2>

            <ul class="social">
                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
            </ul>
        </div>
    </div>
</div>