<app-header-one></app-header-one>

<div class="page-title-area page-title-agent">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Become a General Insurance Agent</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li> General Insurance Agent</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
        <div class="col-lg-8 col-md-8">
            <div class="tab quote-list-tab">
                <div class="tab_content">
                    <div class="tabs_item">

                        <form [formGroup]="AgentRequestForm" (ngSubmit)="onSubmit(AgentRequestForm)">
                            <div class="form-group">
                                <label class="form-label">Name</label>
                                <input type="text" class="form-control" placeholder="Name"  formControlName="Name">
                                <!-- <div *ngIf="ComplaintForm.get('ComplaineeName').touched && ComplaintForm.get('ComplaineeName').invalid">
                                    <div *ngIf="this.ComplaintForm.get('ComplaineeName').hasError('required')" class="text-danger">This Field is Required</div>
                                </div> -->
                            </div>

                            <div class="form-group">
                                <label class="form-label">Email Id</label>
                                <input type="email" class="form-control" placeholder="Email Id"  formControlName="Email">
                                <!-- <div *ngIf="ComplaintForm.get('Email').touched && ComplaintForm.get('Email').invalid">
                                    <div *ngIf="this.ComplaintForm.get('Email').hasError('required')" class="text-danger">This Field is Required</div>
                                </div> -->
                            </div>


                            <div class="form-group">
                                <label class="form-label">Mobile Number</label>
                                <input type="text" class="form-control" placeholder="Mobile Number"  formControlName="Mobileno">
                                <!-- <div *ngIf="ComplaintForm.get('ClaimNumber').touched && ComplaintForm.get('ClaimNumber').invalid">
                                    <div *ngIf="this.ComplaintForm.get('ClaimNumber').hasError('required')" class="text-danger">This Field is Required</div>
                                </div> -->
                            </div>
                            <div class="form-group">
                                <label class="form-label">Any Experience</label>
                                <input type="text" class="form-control" placeholder="Any Experience"  formControlName="Experience">
                                <!-- <div *ngIf="ComplaintForm.get('DetailsofComplaint').touched && ComplaintForm.get('DetailsofComplaint').invalid">
                                    <div *ngIf="this.ComplaintForm.get('DetailsofComplaint').hasError('required')" class="text-danger">This Field is Required</div>
                                </div> -->
                            </div>

                           
                            <button type="submit" class="default-btn">Submit<span></span></button>
                        </form>
                    </div>




                </div>
            </div>
        </div>
    </div>
    </div>
</section>


