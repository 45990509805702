<app-header-one></app-header-one>

<div class="page-title-area page-title-term">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Term Insurance</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Term Insurance</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>Term policy is a multiple-line insurance policy</h3>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Randomised words which don't slightly believable.</p> -->
                       <p>Term insurance is a type of life insurance product where the insurance company provides coverage for a certain term in return for a specific premium amount paid over a period of time. If the policyholder dies during the term specified in the policy, the death payout is paid to the policyholder’s family. Term insurance provides high life coverage at low premium rates.</p>
                       <p>Most people often get into the misconception that the term insurance plans do not provide ample coverage as they do not provide any maturity benefit. However, contrary to this, various term insurance plans offer high insurance coverage at a very affordable premium rate. Moreover, a hassle-free process of premium payment guarantees safety with the convenience of the best term insurance plan. As the simplest form of life insurance product, there are wide ranges of best affordable term insurance plans available in the market. According to your requirement and suitability, you can compare the plans online and choose the most comprehensive one available in the market. This article will give you a better understanding of the best term insurance plans offered by top term life insurance companies along with their respective claim settlement ratios.</p> 
                       <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p> -->
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some for.</p> -->
                         
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="image text-center">
                        <img src="assets/img/services-image/term1.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="insurance-details-desc">
            <h3>Benefits of term insurance that we offer:</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Term insurance helps you secure your family financially in case you are not around. Term insurance plans are considered to be one of the most important types of life insurance plans that one should buy in today’s time. Term insurance not only provides financial protection to your family in the most unfortunate events but also provides you tax benefits. Not only this, you can avail various other benefits available with term plans. Check these term insurance benefits below.</p>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i> High Sum Assured at Affordable Premium</li>
                        <li><i class="fas fa-check"></i>Easy to Understand</li>
                        <li><i class="fas fa-check"></i>Multiple Death Benefit Payout Options</li>
                        <li><i class="fas fa-check"></i>Additional Riders</li>
                        
                        <!-- <li><i class="fas fa-check"></i> Communication Skills to getting in touch</li>
                        <li><i class="fas fa-check"></i> A Career Overview opportunity Available</li>
                        <li><i class="fas fa-check"></i> A good Work Environment For work</li> -->
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Income Tax Benefits</li>
                        <li><i class="fas fa-check"></i>Critical Illness Coverage</li>
                        <li><i class="fas fa-check"></i>Accidental Death Benefit Coverage</li>
                        <li><i class="fas fa-check"></i>Return of Premium Option</li>
                        
                        <!-- <li><i class="fas fa-check"></i> Scientific Skills For getting a better result</li>
                        <li><i class="fas fa-check"></i> Communication Skills to getting in touch</li>
                        <li><i class="fas fa-check"></i> A Career Overview opportunity Available</li>
                        <li><i class="fas fa-check"></i> A good Work Environment For work</li> -->
                    </ul>
                </div>
            </div>

            <h3>Why should you buy a term insurance plan?</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>A term insurance policy is a must for every individual who wants to protect his/her family from a financial crisis in his/her absence. The policy promises a high coverage at very low premiums enabling you to opt for an optimal level of coverage. This optimal coverage ensures that your family is well-taken care of financially in case of your absence. No other investment plan promises financial security which term insurance promises and thus, term insurance is a must-buy for everyone.</p>
            <!-- <p>Two months after Mr Rao moved to a rented apartment in Mumbai, a catastrophic fire engulfed furniture and other appliances worth Rs 8 lakh. Thankfully, he had the right type of property insurance policy and he could manage to settle back to normal life again easily and without any financial stress.</p> -->
            <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/term2.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/term3.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/term4.jpg" alt="image">
                    </figure>
                </li>
            </ul>

            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>While looking for a term insurance policy, one of the major points to consider is the term insurance claim settlement ratio. The Claim Settlement Ratio of a company informs you about the number of the best term insurance plans that are settled by paying back the claims in case of death. By checking CSR, you can easily select the right plan for you and your loved ones from top term life insurance companies. </p>
           <p>Term insurance plans can be bought through the company’s offices or from a life insurance agent. However, under both these options, you would have to buy the plan offline and would not be able to compare the different plans available in the market. Thus, a better alternative to buy term insurance is to buy it online through Grover Care.On Grover Care platform you can compare the best term insurance plans offered by leading life insurance companies.</p>
            <blockquote class="wp-block-quote">
                <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> -->
                <p>Term life insurance is a good defensive game plan.</p>
                <cite>Tom Cruise</cite>
            </blockquote>
            
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>It is a suggestion to all the customers that they make their choices wisely depending on the past performance of the insurers evaluated through their Claim Settlement ratios. Besides that, age, health, and income of the individual are of ample importance because depending on that only would the insurer decide on the sum insured, premium, term, etc. Rest, the provided list of top term life insurance companies is good enough to choose the best term insurance plan.</p>
               
            <div class="truz-post-navigation">
                <div class="prev-link-wrapper">
                    <div class="info-prev-link-wrapper">
                        <a routerLink="/motor-insurance">
                            <span class="image-prev">
                                <img src="assets/img/services-image/motor4.jpg" alt="image">
                                <span class="post-nav-title">Prev</span>
                            </span>
                            <span class="prev-link-info-wrapper">
                                <span class="prev-title">Motor Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="next-link-wrapper">
                    <div class="info-next-link-wrapper">
                        <a routerLink="/life-insurance">
                            <span class="next-link-info-wrapper">
                                <span class="next-title">Life Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                            <span class="image-next">
                                <img src="assets/img/services-image/life4.jpg" alt="image">
                                <span class="post-nav-title">Next</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
