import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-form-validation-message-component",
    templateUrl: "./form-validation-message-component.component.html",
    styleUrls: ["./form-validation-message-component.component.scss"],
})
export class FormValidationMessageComponentComponent implements OnInit {
    _control: any;

    @Input() set control(val: any) {
        this._control = val;
    }
    @Input() validations: [] = [];

    constructor() {}

    ngOnInit(): void {
        const validations = this.validations;
        const control = this._control;
    }
}
