import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Constants } from "./constant.service";
import { GenericCallService } from "./generic-call.service";

@Injectable({
    providedIn: "root",
})
export class FirmDetailService {
    firmDetail: any = [];
    constructor(
        public _genricCall: GenericCallService,
        private constants: Constants
    ) {}

    ngOnInit() {
        this.getOrganizationDetial();
        this.firmDetail = this.getOrganizationDetial();
    }

    getOrganizationDetial() {
        this._genricCall
            .postMethodWithoutToken(
                this.constants.urlpaths().getOrganizationDetial,
                {}
            )
            .subscribe((response) => {
                if (response && response.body.data) {
                    // this.firmDetail = response.body.data;
                    return response.body.data;
                }
            });
    }
}
