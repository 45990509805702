<header class="header-area">

    <!-- Start Top Header -->
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <ul class="top-header-nav">
                        <li><a routerLink="/career">Career</a></li>
                        <li><a routerLink="/our-solutions">Our Solutions</a></li>
                        <li><a routerLink="/claims">Claims</a></li>
                    </ul>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="top-header-right-side">
                        <ul>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-email"></i>
                                </div>
                                <span>Drop us an email:</span>
                                <a href="mailto:{{dataSource && dataSource.emailSer ? dataSource.emailSer : '#'}}">{{dataSource && dataSource.emailSer ? dataSource.emailSer : '#'}}</a>
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-call"></i>
                                </div>
                                <span>Call us:</span>
                                <a href="tel:{{dataSource && dataSource.contactNo ?dataSource.contactNo:'#' }}">{{dataSource && dataSource.contactNo ?dataSource.contactNo:'#' }}</a>
                            </li>
                            <li class="burger-menu" (click)="openQuote()">
                                <a class="default-btn">Enquiry</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Top Header -->

    <!-- Start Navbar Area -->
    <div class="navbar-area">
        <div class="truz-responsive-nav">
            <div class="container">
                <div class="truz-responsive-menu">
                    <div class="logo">
                        <img src="assets/img/logo.png" alt="logo" style="width:15%;">
                        <a routerLink="/">
                            <strong>Grover Care</strong>
                            <!-- <p>Insurance Service Provider</p> -->
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="truz-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <img src="assets/img/logo.png" alt="logo" style="width:7%;">
                    <a class="navbar-brand" routerLink="/">
                        <strong>Grover Care</strong>
                        <p>Insurance Service Provider</p>
                    </a>

                    <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                        <ul class="navbar-nav">
                            <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Home <i
                                        class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Home One</a></li>

                                    <li class="nav-item"><a routerLink="/home-two" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home
                                            Two</a></li>

                                    <li class="nav-item"><a routerLink="/home-three" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home
                                            Three</a></li>

                                    <li class="nav-item"><a routerLink="/home-four" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home
                                            Four</a></li>
                                </ul>
                            </li> -->

                            <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                            <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Health <i class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/health-insurance
                                        " class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Individual</a></li>
                                    <li class="nav-item"><a routerLink="/#" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Group</a></li>
                                </ul>
                            </li> -->

                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Health <i
                                        class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/individual-health-insurance" class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Individual</a></li>
                                    <li class="nav-item"><a routerLink="/group-health-insurance" class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Group</a></li>
                                </ul>
                            </li>


                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Life <i
                                        class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/term-insurance" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Term
                                            Insurance</a></li>
                                    <li class="nav-item"><a routerLink="/childcare-plans" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Child
                                            Care</a></li>
                                    <li class="nav-item"><a routerLink="/retirement-plans" class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Retirement</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">General <i
                                        class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/motor-insurance" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Motor
                                            Insurance</a></li>
                                    <li class="nav-item"><a routerLink="/home-insurance" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home
                                            Insurance</a></li>
                                    <li class="nav-item"><a routerLink="/travel-insurance" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Travel
                                            Insurance</a></li>
                                </ul>
                            </li>

                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Contact</a></li>


                            <ul *ngIf="this._commonService.userLogin$ | async; else login" class="navbar-nav mt-3">
                                <li class="nav-item"><button href="javascript:void(0)" class="default-btn">My Account <i
                                            class="flaticon-down-arrow"></i></button>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="#" class="nav-link">My
                                                Profile</a></li>
                                        <li class="nav-item"><a routerLink="#" class="nav-link" >My
                                                Policies</a></li>
                                        <li class="nav-item"><a routerLink="#" class="nav-link" >My
                                                transactions</a></li>
                                        <li class="nav-item"><a (click)="logout()" class="nav-link" >Sign
                                                out</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </ul>
                        <ng-template #login>
                            <li (click)="onlogin()" class="burger-menu" style="margin-top:20px;">
                                <a routerLink="/" class="default-btn" style="color:#fff;">Login <span></span></a>
                            </li>
                        </ng-template>
                        <div class="others-option">
                            <div class="option-item">
                                <i class="search-btn flaticon-search"></i>
                                <i class="close-btn flaticon-cross-out"></i>

                                <div class="search-overlay search-popup">
                                    <div class='search-box'>
                                        <form class="search-form">
                                            <input class="search-input" name="search" placeholder="Search" type="text">

                                            <button class="search-button" type="submit"><i
                                                    class="flaticon-search"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- <li *ngIf="this.userLogin$ | async;else login">
                                <a (click)="logout()" class="default-btn">Logout <span></span></a>
                            </li> -->
                            
                        </div>
                        
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <!-- End Navbar Area -->

</header>