<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Business Insurance</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Business Insurance</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>Business policy is a multiple-line insurance policy</h3>
                        <p>Business Policy defines the scope or spheres within which decisions can be taken by the subordinates in an organization. It permits the lower level management to deal with the problems and issues without consulting top level management every time for decisions.</p>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some for.</p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="image text-center">
                        <img src="assets/img/services-image/img2.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="insurance-details-desc">
            <h3>Four major elements that we offer:</h3>
            <p>Policy is a blueprint of the organizational activities which are repetitive/routine in nature. While strategy is concerned with those organizational decisions which have not been dealt/faced beforein same form.</p>

            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i> Scientific Skills For getting a better result</li>
                        <li><i class="fas fa-check"></i> Communication Skills to getting in touch</li>
                        <li><i class="fas fa-check"></i> A Career Overview opportunity Available</li>
                        <li><i class="fas fa-check"></i> A good Work Environment For work</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i> Scientific Skills For getting a better result</li>
                        <li><i class="fas fa-check"></i> Communication Skills to getting in touch</li>
                        <li><i class="fas fa-check"></i> A Career Overview opportunity Available</li>
                        <li><i class="fas fa-check"></i> A good Work Environment For work</li>
                    </ul>
                </div>
            </div>

            <h3>Setting the mood with incense</h3>
            <p>The strategy statement of a firm sets the firm’s long-term strategic direction and broad policy directions. It gives the firm a clear sense of direction and a blueprint for the firm’s activities for the upcoming years.</p>

            <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/img1.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/img2.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/img3.jpg" alt="image">
                    </figure>
                </li>
            </ul>

            <p>.</p>

            <blockquote class="wp-block-quote">
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                <cite>Tom Cruise</cite>
            </blockquote>
            
            <p>Business policies are the guidelines developed by an organization to govern its actions. They define the limits within which decisions must be made. Business policy also deals with acquisition of resources with which organizational goals can be achieved. Business policy is the study of the roles and responsibilities of top level management, the significant issues affecting organizational success and the decisions affecting organization in long-run.</p>

            <div class="truz-post-navigation">
                <div class="prev-link-wrapper">
                    <div class="info-prev-link-wrapper">
                        <a routerLink="/health-insurance">
                            <span class="image-prev">
                                <img src="assets/img/services-image/img1.jpg" alt="image">
                                <span class="post-nav-title">Prev</span>
                            </span>
                            <span class="prev-link-info-wrapper">
                                <span class="prev-title">Health Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="next-link-wrapper">
                    <div class="info-next-link-wrapper">
                        <a routerLink="/life-insurance">
                            <span class="next-link-info-wrapper">
                                <span class="next-title">Life Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                            <span class="image-next">
                                <img src="assets/img/services-image/img3.jpg" alt="image">
                                <span class="post-nav-title">Next</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>