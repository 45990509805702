import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class Constants {
    urlpaths() {
        const paths = {
            auth: "AuthenticationWebApi/dologin",
            //user
            registerUser: "user/register",
            getUserList: "user/getuserlist",
            // country
            getCountry: "Country/GetCountry",
            addEditCountry: "Country/AddEditCountry",
            //Organization
            getOrganizationList: "Organization/GetOrganizationList",
            addEditOrganization: "Organization/AddEditOrganization",
            //Quote
            addEditQuote: "Quotes/AddEditQuoteRequest",
            // Attribute
            getAttributeListById: "AttributeMst/getAttributeListById/",
            //Grievance Form
            GetAttributeMst: "AttributeMst/GetAttributeMst",
            AddGrievanceForm: "Grievance/AddGrievanceForm",
            //AgentRequest
            AddAgentRequest:"AgentRequest/AddEditAgentRequest",
            
            //OrganizationDetail
            getOrganizationDetial: "Organization/GetOrganizationDetial"

        };
        return paths;
    }
}
