<app-header-one></app-header-one>

<div class="page-title-area page-title-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Home Insurance</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Home Insurance</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>Homeowner's policy is a multiple-line insurance policy</h3>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Randomised words which don't slightly believable.</p> -->
                        <p>Home insurance is a type of property insurance that provides coverage to the policyholder from the unforeseen loss or damage caused to the house structure as well as its content.Home insurance is popularly known as homeowner’s insurance. It is a sort of property insurance covering private residences.</p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p> -->
                        <p>Home insurance will provide you cover against any ‘Acts of God’. Moreover, even with the latest safety equipment accessible in the market, there are chances of burglary or robbery taking place. Therefore, it is essential to take extra care and opt for house insurance policy coverage, which will provide you cover against any damage/loss incurred due to any natural or man-made calamity.</p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some for.</p> -->
                         <p>Having property insurance essentially assures you of security and certainty in an otherwise uncertain world.</p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="image text-center">
                        <img src="assets/img/services-image/home1.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="insurance-details-desc">
            <h3>Four major elements that we offer:</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Home insurance plans offer coverage of the liabilities that arise out of damages to the house and/or the contents due to man-made reasons like burglary, theft, etc. as well as natural calamities like fire, floods, lightning etc. Here are some of the key features of the best home insurance policy:</p>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <!-- <li><i class="fas fa-check"></i> Scientific Skills For getting a better result</li> -->
                        <li><i class="fas fa-check"></i>Cover for Damages</li>
                        <li><i class="fas fa-check"></i>Covers the Building</li>
                        
                        <!-- <li><i class="fas fa-check"></i> Communication Skills to getting in touch</li>
                        <li><i class="fas fa-check"></i> A Career Overview opportunity Available</li>
                        <li><i class="fas fa-check"></i> A good Work Environment For work</li> -->
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Covers Additional Structures</li>
                        <li><i class="fas fa-check"></i>Covers Personal Property/Possessions</li>
                        <!-- <li><i class="fas fa-check"></i> Scientific Skills For getting a better result</li>
                        <li><i class="fas fa-check"></i> Communication Skills to getting in touch</li>
                        <li><i class="fas fa-check"></i> A Career Overview opportunity Available</li>
                        <li><i class="fas fa-check"></i> A good Work Environment For work</li> -->
                    </ul>
                </div>
            </div>

            <h3>Setting the mood with incense</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>Home insurance policy is for people who have a home whether own or rented. There is a myth that only home-owners should buy a home insurance plan. Let us tell you a case of Mr Vijay Rao and how having a property insurance policy saved him during a crisis.</p>
            <p>Two months after Mr Rao moved to a rented apartment in Mumbai, a catastrophic fire engulfed furniture and other appliances worth Rs 8 lakh. Thankfully, he had the right type of property insurance policy and he could manage to settle back to normal life again easily and without any financial stress.</p>
            <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/home2.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/home3.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/home4.jpg" alt="image">
                    </figure>
                </li>
            </ul>

            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>It is a misconception that only house owners should buy home insurance. Although, the house may belong to a landlord it has contents which belong to you which makes it imperative to buy a property and casualty insurance. Irrespective of the fact that whether you are a tenant or owner, buying home insurance should be your top priority.</p>
           <p>Even, there are various house insurance providers, which provide exciting discounts to people who have bought over 3 home insurance covers. However, do not let discounts make you buy those covers, which you do not need. For instance, if you are a landlord then avoid buying a property insurance policy to cover the contents of the house. Leave that to the tenant. There are various property and casualty insurance providers who offer discounts if you buy it for a longer duration. You can ensure the house for up to 10 years, which not only gives peace of mind but you also get a chance to earn lucrative discounts.</p>
            <blockquote class="wp-block-quote">
                <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> -->
                <p>Home insurance coverage gives you peace of mind.</p>
                <cite>Tom Cruise</cite>
            </blockquote>
            
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>From free home insurance policy quotes to compare among best home insurance providers in India, we at Grover Care are here to secure your home from all damages/losses. With us, insuring your valuable possession, ‘Home’ is now a matter of a few clicks. One can buy or renew an existing policy through our viable platform.</p>
               
            <div class="truz-post-navigation">
                <div class="prev-link-wrapper">
                    <div class="info-prev-link-wrapper">
                        <a routerLink="/general-insurance">
                            <span class="image-prev">
                                <img src="assets/img/services-image/general3.jpg" alt="image">
                                <span class="post-nav-title">Prev</span>
                            </span>
                            <span class="prev-link-info-wrapper">
                                <span class="prev-title">General Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="next-link-wrapper">
                    <div class="info-next-link-wrapper">
                        <a routerLink="/health-insurance">
                            <span class="next-link-info-wrapper">
                                <span class="next-title">Health Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                            <span class="image-next">
                                <img src="assets/img/services-image/health4.jpg" alt="image">
                                <span class="post-nav-title">Next</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>