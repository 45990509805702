import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { TestimonialsComponent } from "./components/pages/testimonials/testimonials.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { HomeInsuranceComponent } from "./components/pages/home-insurance/home-insurance.component";
import { BusinessInsuranceComponent } from "./components/pages/business-insurance/business-insurance.component";
import { HealthInsuranceComponent } from "./components/pages/health-insurance/health-insurance.component";
import { TravelInsuranceComponent } from "./components/pages/travel-insurance/travel-insurance.component";
import { CarInsuranceComponent } from "./components/pages/car-insurance/car-insurance.component";
import { LifeInsuranceComponent } from "./components/pages/life-insurance/life-insurance.component";
import { CaseStudyComponent } from "./components/pages/case-study/case-study.component";
import { CaseStudyDetailsComponent } from "./components/pages/case-study-details/case-study-details.component";
import { EventsDetailsComponent } from "./components/pages/events-details/events-details.component";
import { EventsComponent } from "./components/pages/events/events.component";
import { ServicesOneComponent } from "./components/pages/services-one/services-one.component";
import { ServicesTwoComponent } from "./components/pages/services-two/services-two.component";
import { BlogGridComponent } from "./components/pages/blog-grid/blog-grid.component";
import { BlogRightSidebarComponent } from "./components/pages/blog-right-sidebar/blog-right-sidebar.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { TermInsuranceComponent } from "./components/pages/term-insurance/term-insurance.component";
import { GeneralInsuranceComponent } from "./components/pages/general-insurance/general-insurance.component";
import { ChildcarePlansComponent } from "./components/pages/childcare-plans/childcare-plans.component";
import { RetirementPlansComponent } from "./components/pages/retirement-plans/retirement-plans.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { TermConditionsComponent } from "./components/pages/term-conditions/term-conditions.component";
import { CareerComponent } from "./components/pages/career/career.component";
import { OurSolutionsComponent } from "./components/pages/our-solutions/our-solutions.component";
import { ClaimsComponent } from "./components/pages/claims/claims.component";
import { GrouphealthInsuranceComponent } from "./components/pages/grouphealth-insurance/grouphealth-insurance.component";
import { IndividualhealthInsuranceComponent } from "./components/pages/individualhealth-insurance/individualhealth-insurance.component";
import { GrievanceFormComponent } from "./components/pages/grievance-form/grievance-form.component";
import { MyProfileComponent } from "./components/pages/my-profile/my-profile.component";
import { MyPoliciesComponent } from "./components/pages/my-policies/my-policies.component";
import { InsuranceAgentComponent } from "./components/pages/insurance-agent/insurance-agent.component";
import { AgentDetailsComponent } from "./components/pages/agent-details/agent-details.component";
import { EnquiryComponent } from "./components/pages/enquiry/enquiry.component";
import { ThankYouComponent } from "./components/pages/shared/components/thank-you/thank-you.component";
import { MotorInsuranceLandingComponent } from "./components/pages/motor-insurance-landing/motor-insurance-landing.component";

const routes: Routes = [
    { path: "", component: HomeOneComponent },
    { path: "about", component: AboutComponent },
    { path: "team", component: TeamComponent },
    { path: "services-one", component: ServicesOneComponent },
    { path: "services-two", component: ServicesTwoComponent },
    { path: "home-insurance", component: HomeInsuranceComponent },
    { path: "health-insurance", component: HealthInsuranceComponent },
    {
        path: "group-health-insurance",
        component: GrouphealthInsuranceComponent,
    },
    {
        path: "individual-health-insurance",
        component: IndividualhealthInsuranceComponent,
    },
    { path: "travel-insurance", component: TravelInsuranceComponent },
    { path: "motor-insurance", component: CarInsuranceComponent },
    {
        path: "motor-insurance-service",
        component: MotorInsuranceLandingComponent,
    },
    { path: "general-insurance", component: GeneralInsuranceComponent },
    { path: "term-insurance", component: TermInsuranceComponent },
    { path: "life-insurance", component: LifeInsuranceComponent },
    { path: "childcare-plans", component: ChildcarePlansComponent },
    { path: "retirement-plans", component: RetirementPlansComponent },
    { path: "case-study", component: CaseStudyComponent },
    { path: "case-study-details", component: CaseStudyDetailsComponent },
    { path: "events", component: EventsComponent },
    { path: "events-details", component: EventsDetailsComponent },
    { path: "blog-grid", component: BlogGridComponent },
    { path: "blog-right-sidebar", component: BlogRightSidebarComponent },
    { path: "blog-details", component: BlogDetailsComponent },
    { path: "pricing", component: PricingComponent },
    { path: "testimonials", component: TestimonialsComponent },
    { path: "faq", component: FaqComponent },
    { path: "error", component: ErrorComponent },
    { path: "contact", component: ContactComponent },
    { path: "privacy-policy", component: PrivacyPolicyComponent },
    { path: "term-conditions", component: TermConditionsComponent },
    { path: "career", component: CareerComponent },
    { path: "our-solutions", component: OurSolutionsComponent },
    { path: "claims", component: ClaimsComponent },
    { path: "grievance-form", component: GrievanceFormComponent },
    { path: "my-profile", component: MyProfileComponent },
    { path: "my-policies", component: MyPoliciesComponent },
    { path: "agentrequest", component: InsuranceAgentComponent },
    { path: "agent", component: AgentDetailsComponent },
    { path: "agent", component: AgentDetailsComponent },
    { path: "enquiryform", component: EnquiryComponent },
    { path: "thankyou", component: ThankYouComponent },
    { path: "motor-insurance-thankyou", component: ThankYouComponent },
    { path: "**", component: ErrorComponent }, // this line always be at the end
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
