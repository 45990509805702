<app-header-one></app-header-one>

<div class="page-title-area page-title-individualhealth">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Individual Health Insurance</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Individual Health Insurance</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>Individual Health policy is a multiple-line insurance policy</h3>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Randomised words which don't slightly believable.</p> -->
                        <p>The COVID-19 pandemic made people realise the importance of staying healthy and keeping themselves financially secure. For this reason, people are focusing  on buying health insurance today more than ever. People in India have either enrolled themselves in a government health insurance scheme or an individual health insurance policy.</p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p> -->
                        <p>Individual health insurance is an insurance plan that offers medical coverage to a person on an individual sum insured basis. It refers to the type of health insurance plan that needs to be purchased separately for each family member. It allows you to customize the coverage as per your own health needs and not of the entire family.</p>
                        <p>Most individual health insurance plans provide comprehensive coverage to an individual for emergency medical expenses resulting from hospitalization costs,day care procedures, road ambulance services, alternative treatment, organ donor expenses, etc. However, the premium and coverage of an individual health policy cannot be shared amongst your family members.</p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some for.</p> -->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="image text-center">
                        <img src="assets/img/services-image/individualhealth1.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="insurance-details-desc">
            <!-- <h3>Four major elements that we offer:</h3> -->
            <h3>Features and Benefits of Individual Health Insurance</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
                <p>An individual health insurance policy comes with the following features and benefits:</p>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Solo Coverage </li>
                        <li><i class="fas fa-check"></i>Suitable for Older People</li>
                        <li><i class="fas fa-check"></i>Sum Insured </li>
                        <li><i class="fas fa-check"></i>Wider Coverage</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Add-on Covers</li>
                        <li><i class="fas fa-check"></i>Multiple Claims</li>
                        <li><i class="fas fa-check"></i>Family Discount</li>
                        <li><i class="fas fa-check"></i>Renewal for Life</li>
                    </ul>
                </div>
            </div>

            <h3>Is Cashless Treatment Available under Individual Health Insurance Policy?</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>Yes. A cashless treatment facility is available under the individual health insurance policy. Under this facility, your insurance provider pays your hospitalization bill amount directly to the network hospital where you were undergoing treatment. It does not require you to pay the medical expenses out of your own pocket. You can avail cashless hospitalization facility at any of the network hospitals of your insurance company.</p>
            <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/individualhealth2.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/individualhealth3.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/individualhealth4.jpg" alt="image">
                    </figure>
                </li>
            </ul>

            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <h3>Are There Any Waiting Periods under Individual Health Insurance Plans?</h3>
            <p>Yes. All individual health insurance plans come with certain waiting periods. Firstly, you cannot raise a non-accidental claim during the first 30 days of the policy commencement. Specific diseases and surgical procedures like arthritis, cataract, joint replacement surgery, bariatric surgery, etc. are covered after a waiting period of 1 to 3 years depending on the plan. Similarly, you can raise a claim for pre-existing diseases after a waiting period of 2 to 4 years as per the policy terms.</p>
           
           <h3>How Much Individual Health Insurance Coverage Should You Have?</h3>
           <p>As per insurance experts, the coverage under your health insurance policy should be at least 50% of your yearly income. A minimum of Rs 10 lakh of medical insurance cover is required to meet expensive treatments like bypass surgery, COVID-19 treatment and related complications, etc. The coverage can go as high as Rs 1 crore depending on the plan.</p>

            <blockquote class="wp-block-quote">
                <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> -->
                <p>Health insurance should be a given for every citizen.</p>
                <cite>Tom Cruise</cite>
            </blockquote>
            
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Individual health insurance plans are a must for people who have their own health issues. It offers your sum insured amount exclusively for you and you do not have to share it with your family members on a floater basis. So go ahead and buy an individual health insurance policy for yourself from Policybazaar.com after an in-depth comparison.</p>
            <div class="truz-post-navigation">
                <div class="prev-link-wrapper">
                    <div class="info-prev-link-wrapper">
                        <a routerLink="/home-insurance">
                            <span class="image-prev">
                                <img src="assets/img/services-image/home4.jpg" alt="image">
                                <span class="post-nav-title">Prev</span>
                            </span>
                            <span class="prev-link-info-wrapper">
                                <span class="prev-title">Home Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="next-link-wrapper">
                    <div class="info-next-link-wrapper">
                        <a routerLink="/travel-insurance">
                            <span class="next-link-info-wrapper">
                                <span class="next-title">Travel Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                            <span class="image-next">
                                <img src="assets/img/services-image/travel4.jpg" alt="image">
                                <span class="post-nav-title">Next</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

