<div class="sidebar-about-area">
    <div class="title">
    </div>
</div>
<div class="bg-login">
    <!--wrapper-->
    <div class="wrapper">
        <div class="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
            <div class="container">
                <div class="row">
                    <div class="col mx-auto">
                        <!-- <div class="mb-4 text-center">
                                <img src="assets/images/logo-img.png" width="180" alt="" />
                            </div> -->
                        <div class="card">
                            <div class="card-body">
                                <div class="border p-4 rounded">
                                    <div class="text-center">
                                        <h3 class="">Sign up</h3>
                                        <!-- <p>Don't have an account yet? <a href="JavaScript:;" (click)="onSignup()">Sign up here</a>
                                            </p> -->
                                    </div>
                                    <div class="form-body">
                                        <ng-container *ngIf="!OTPsend; else OtpSent">
                                            <form class="row g-3" [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                                                <div *ngIf="message">{{message}}</div>
                                                <div class="col-12">
                                                    <label for="inputChoosePassword" class="form-label">Name
                                                    </label>
                                                    <div class="input-group" id="show_hide_password">
                                                        <input type="text" class="form-control "
                                                            id="inputChoosePassword" formControlName="name"
                                                            placeholder="Name" required="required">
                                                    </div>
                                                    <div *ngIf=" onFormSubmit && signupForm.controls.name.hasError('required')
                                                  ">
                                                        Name is required
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <label for="inputChoosePassword" class="form-label">Date of Birth
                                                    </label>
                                                    <div class="input-group" id="show_hide_password">
                                                        <input type="date" class="form-control "
                                                            id="inputChoosePassword" formControlName="dob"
                                                            placeholder="Date of birth" required="required">
                                                    </div>

                                                    <div *ngIf="onFormSubmit &&  signupForm.controls.dob.errors ">
                                                        Date of Birth is required
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <label for="inputChoosePassword" class="form-label">Mobile
                                                        No.</label>
                                                    <div class="input-group" id="show_hide_password">
                                                        <input type="text" class="form-control "
                                                            id="inputChoosePassword" formControlName="mobileno"
                                                            placeholder="Mobile No." required="required">
                                                    </div>

                                                    <div *ngIf="onFormSubmit && signupForm.controls.mobileno.errors  ">
                                                        Mobile No. is required
                                                    </div>

                                                </div>
                                                <div class="col-12">
                                                    <label for="inputChoosePassword" class="form-label">Email
                                                    </label>
                                                    <div class="input-group" id="show_hide_password">
                                                        <input type="text" class="form-control "
                                                            id="inputChoosePassword" formControlName="email"
                                                            placeholder="Email" required="required">
                                                    </div>
                                                    <div *ngIf="onFormSubmit &&  signupForm.controls.email.errors">
                                                        Email is required
                                                    </div>
                                                </div>

                                                <!-- [disabled]="!signupForm.valid" -->

                                                <div class="col-12">
                                                    <div class="d-grid">
                                                        <button type="submit" class="default-btn"><i
                                                                class="bx bxs-lock-open"></i>Register</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </ng-container>
                                        <ng-template #OtpSent>
                                            <form class="row g-3" [formGroup]="signupForm" (ngSubmit)="onOTPSubmit()">
                                                <div *ngIf="message">{{message}}</div>
                                                <div class="col-12">
                                                    <label for="inputChoosePassword" class="form-label">
                                                        OTP</label>
                                                    <div class="input-group" id="show_hide_password">
                                                        <input  class="form-control border-end-0 " maxlength="6" minlength="6"
                                                            id="inputChoosePassword" formControlName="otp"
                                                            placeholder="Enter 6 digit OTP">
                                                        <div *ngIf="onFormSubmit &&  signupForm.controls.otp.errors">
                                                            OTP is required
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="d-grid">
                                                        <button type="submit" class="default-btn"><i
                                                                class="bx bxs-lock-open"></i>Verify</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end row-->
            </div>
        </div>
    </div>
    <!--end wrapper-->
</div>

<div class="sidebar-contact-area">
    <div class="sidebar-contact-info">
        <div class="contact-info-content">
            <p>First time user?<a href=""> Sign up</a></p>
            <h2>
                <!-- <a href="tel:08146490900">+91 81464-90900</a> -->
                <a href="tel:07087020244">+91 70870-20244</a>
                <span>OR</span>
                <a href="mailto:info@grovercare.com">info@grovercare.com</a>
            </h2>

            <ul class="social">
                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
            </ul>
        </div>
    </div>
</div>