<app-header-one></app-header-one>

<div class="page-title-area page-title-motor">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Motor Insurance Service</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Motor Insurance Service</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-6 col-md-12">
                    <div class="image text-center">
                        <img src="assets/img/grover-care-car-banner.png" alt="grover-care-car-banner">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>Motor policy is a multiple-line insurance policy</h3>

                        <p>Vehicle insurance or motor insurance is meant for cars, two wheelers and other road vehicles.
                            A motor package policy protects the insured vehicle against the damages caused due to
                            accidents and natural disasters.</p>
                        <p>In addition to the own vehicle damage, motor vehicle insurance also provides the mandatory
                            coverage for third-party liabilities.</p>
                        <p>Simply put, a comprehensive vehicle insurance allows for worry-free drives by curbing your
                            vehicle repair expenses and helping you adhere to the law when on road.</p>
                        <button type="button" class="default-btn" (click)="scrollToSection('quoteSection')">
                            Insure Your Vehicle Today
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <section class="services-area">
            <div class="container ">
                <div class="row justify-content-center">
                    <div class="col-sm-4 col-md-4 col-lg-2">
                        <div class="single-services-box">
                            <div class="icon">
                                <img class="h100" src="assets/img/motor/bike.png"
                                     alt="grover-care-two-wheeler-insurance">
                                <div class="icon-bg">
                                    <img src="assets/img/icon-bg1.png" alt="image">
                                    <img src="assets/img/icon-bg2.png" alt="image">
                                </div>
                            </div>

                            <h5><a routerLink="/motor-insurance">Two Wheeler</a></h5>

                            <div class="box-shape">
                                <img src="assets/img/box-shape1.png" alt="image">
                                <img src="assets/img/box-shape2.png" alt="image">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 col-md-4 col-lg-2">
                        <div class="single-services-box">
                            <div class="icon">
                                <img class="h100" src="assets/img/motor/scooter.png"
                                     alt="grover-care-scooter-insurance">
                                <div class="icon-bg">
                                    <img src="assets/img/icon-bg1.png" alt="image">
                                    <img src="assets/img/icon-bg2.png" alt="image">
                                </div>
                            </div>

                            <h5><a routerLink="/health-insurance">Scooter</a></h5>

                            <div class="box-shape">
                                <img src="assets/img/box-shape1.png" alt="image">
                                <img src="assets/img/box-shape2.png" alt="image">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 col-md-4 col-lg-2">
                        <div class="single-services-box">
                            <div class="icon">
                                <img class="h100" src="assets/img/motor/car.png" alt="grover-care-car-insurance">
                                <div class="icon-bg">
                                    <img src="assets/img/icon-bg1.png" alt="image">
                                    <img src="assets/img/icon-bg2.png" alt="image">
                                </div>
                            </div>

                            <h5><a routerLink="/health-insurance">Car</a></h5>

                            <div class="box-shape">
                                <img src="assets/img/box-shape1.png" alt="image">
                                <img src="assets/img/box-shape2.png" alt="image">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 col-md-4 col-lg-2">
                        <div class="single-services-box">
                            <div class="icon">
                                <img class="h100" src="assets/img/motor/three-wheeler.png"
                                     alt="grover-care-three-wheeler-insurance">

                                <div class="icon-bg">
                                    <img src="assets/img/icon-bg1.png" alt="image">
                                    <img src="assets/img/icon-bg2.png" alt="image">
                                </div>
                            </div>

                            <h5><a routerLink="/motor-insurance">Three Wheeler</a></h5>

                            <div class="box-shape">
                                <img src="assets/img/box-shape1.png" alt="image">
                                <img src="assets/img/box-shape2.png" alt="image">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 col-md-4 col-lg-2">
                        <div class="single-services-box">
                            <div class="icon">
                                <img class="h100" src="assets/img/motor/commercial.png"
                                     alt="grover-care-commercial-insurance">
                                <div class="icon-bg">
                                    <img src="assets/img/icon-bg1.png" alt="image">
                                    <img src="assets/img/icon-bg2.png" alt="image">
                                </div>
                            </div>

                            <h5><a routerLink="/travel-insurance">Commercial</a></h5>

                            <div class="box-shape">
                                <img src="assets/img/box-shape1.png" alt="image">
                                <img src="assets/img/box-shape2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="insurance-details-desc">
            <h3>Four major benefits of motor insurance:</h3>
            <p>Motor insurance is a legal agreement between you and the insurance provider wherein you promise you pay a
                certain amount of premium to get coverage for your vehicle in case of theft, loss, accident, third-party
                injury, etc. As per the Motor Vehicles Act 2017, vehicle should have a valid third-party insurance.
                Following are some of the advantages of having a motor insurance in India.</p>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Coverage against accident</li>
                        <li><i class="fas fa-check"></i>Coverage against natural disaster</li>
                        <li><i class="fas fa-check"></i>Legal protection</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Death Benefits </li>
                        <li><i class="fas fa-check"></i>No Claim Bonus</li>
                        <li><i class="fas fa-check"></i>Key protection cover</li>
                    </ul>
                </div>
            </div>

            <h3>Setting the mood with incense</h3>
            <p> There are generally two types of motor insurance policies- third-party plans and comprehensive plans. To
                calculate the four wheeler premium online, first you need to determine the type of plan you want to
                choose.
                If you invest in a third-party policy, you can calculate the premium by first checking the premium
                determined by the Insurance Regulatory and Development Authority of India (IRDAI) and then adding 18%
                GST to it.
                However, for comprehensive policies, various details are required before calculating the premium and it
                varies from company to company depending on the price. Thus, you can use the premium calculator offered
                by the other companies or online platforms.</p>
            <div class="row justify-content-center">
                <div class="col-lg-12 col-md-12">
                    <img src="assets/img/gorver-care-achivements.png" alt="grover-care-achievements">
                </div>
            </div>
            <p>While planning to buy insurance for your new vehicles, you might want to avail the best motor insurance
                policy available and suitable to your requirements as well. If you’re in a dilemma about the company,
                then Grover Care is just the right place for you. You get to compare all the plans before choosing the
                right one for you, based on various parameters. </p>
        </div>
    </div>
</section>

<section class="services-area ">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <img class="h100" src="assets/img/motor/low-premium.png" alt="grover-care-car-banner">

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3>Incredibly Low Premium</h3>
                    <p>Insuring things that matter to you shouldn’t come at a cost. That’s why when you buy from us, you
                        get insurance that
                        fits your pocket.</p>
                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <img class="h100" src="assets/img/motor/quickly.png" alt="grover-care-car-banner">

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/health-insurance">Quickly & Easily</a></h3>
                    <p>We’re easy, effortless and 100% digital. Forget paperwork, just access all our services with just
                        a few clicks.</p>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <img class="h100" src="assets/img/motor/hassle-free.png" alt="grover-care-car-banner">

                        <div class="icon-bg">
                            <img src="assets/img/icon-bg1.png" alt="image">
                            <img src="assets/img/icon-bg2.png" alt="image">
                        </div>
                    </div>

                    <h3><a routerLink="/travel-insurance">Hassle-Free Claim</a></h3>
                    <p>We’re on a mission to make insurance claims stress-free. Just give us a call and experience
                        cashless, speedy claim settlements.</p>

                    <div class="box-shape">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <img src="assets/img/box-shape2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="quote-area pb-70" id="quoteSection">
    <div class="container">
        <div class="row align-items-center">
            <div class="section-title">
                <h1>Get A Free Quote</h1>
                <!-- <p>From numerous available quotes, choose the one that best suits your requirements and budget.</p> -->
                <p>Fill the form and our experts will reply you with a quote very soon</p>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="quoteImage">
                    <img src="assets/img/grovercare-quote.jpg" alt="grover-care-quote">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="tab quote-list-tab">
                    <app-enquiry-form [showSuccessMessage]="false" [showDOBField]="false"
                                      defaultInsuranceType="3"></app-enquiry-form>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="faq-area  pb-70 ">
    <div class="container">
        <div class="section-title">
            <h2>Motor Insurance FAQ's</h2>
        </div>
        <app-faq></app-faq>
    </div>
</section>