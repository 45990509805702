<footer class="footer-area">
    <div class="container">
        <!-- <div class="subscribe-area">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="subscribe-content">
                        <h2>Join Our Newsletter</h2>
                        <p>Stay in touch with the latest events, news and offers at Grover Care An Insurance Broker.
                            Submit your request by clicking the subscribe now button. Your information will not be sold
                            or distributed to any unrelated third party companies.
                        </p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="subscribe-form">
                        <form class="newsletter-form">
                            <input type="email" class="input-newsletter" placeholder="Enter your email address"
                                name="EMAIL" required autocomplete="off">
                            <button type="submit">Subscribe Now <i class="flaticon-right-chevron"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <!-- <a routerLink="/"><img src="assets/img/white-logo.png" alt="image"></a> -->
                        <h3 style="color:white;">Grover Care</h3><br />
                        <h5 style="color:white;">Insurance Service Provider</h5>
                        <p>Grover Care is a pioneer in laying down systems for smooth and orderly conduct of the
                            business. The strength of the
                            Company lies in its highly trained and motivated work force that covers various disciplines
                            and has vast expertise. If you have any questions, please feel free to contact us.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                        <li><a routerLink="/health-insurance">Health Insurance</a></li>
                        <li><a routerLink="/motor-insurance">Motor Insurance</a></li>
                        <li><a routerLink="/life-insurance">Life Insurance</a></li>
                        <li><a routerLink="/retirement-plans">Retirement Plans</a></li>
                        <li><a routerLink="/childcare-plans">Child Care Plans</a></li>
                        <li><a routerLink="/grievance-form">Grievance Form</a></li>
                        <!-- <li><a routerLink="/agentrequest"> Agent Request</a></li> -->
                        <li><a routerLink="/agent">Become an Agent</a></li>
                        <li><a (click)="redirectToBackoffice()">Back Office Portal</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="footer-contact-info">
                        <li><span>Location:</span> <a
                               href="https://goo.gl/maps/T5gSLkHN7vw8s6r57">{{dataSource?.address}}
                                <!-- Grover Insurance Brokers Pvt. Ltd.<br />BXXX 1627/725/1, Sherpur, Ludhiana, Punjab,
                                141001 -->
                            </a></li>
                        <li><span>Email:</span> <a
                               href="mailto:{{dataSource && dataSource.emailSer ? dataSource.emailSer : '#'}}">
                                {{dataSource && dataSource.emailSer ? dataSource.emailSer : '#'}}
                            </a></li>
                        <li><span>Phone:</span> <a
                               href="tel:{{dataSource && dataSource.contactNo ?dataSource.contactNo:'#' }}">
                                {{dataSource && dataSource.contactNo ?dataSource.contactNo:'#' }}
                            </a></li>
                        <!-- <li><a href="https://goo.gl/maps/T5gSLkHN7vw8s6r57" target="_blank">View Location on
                                GoogleMap</a></li> -->
                    </ul>
                    <ul class="social">
                        <li><a [href]="dataSource && dataSource.facebook ? dataSource.facebook : '#'" target="_blank"><i
                                   class="fab fa-facebook-f"></i></a></li>
                        <li><a [href]="dataSource && dataSource.whatsapp ? dataSource.whatsapp : '#'" target="_blank"><i
                                   class="fab fa-whatsapp"></i></a></li>
                        <li><a [href]="dataSource && dataSource.linkedin ? dataSource.linkedin : '#'" target="_blank"><i
                                   class="fab fa-linkedin-in"></i></a></li>
                        <li><a [href]="dataSource && dataSource.instagram ? dataSource.instagram : '#'"
                               target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p>Developed by <a href="http://wintechsolutions.net/" target="_blank">Wintech Solutions</a></p>
                </div>

                <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/term-conditions">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="go-top active whatsAppIcon"><i class="fab fa-whatsapp"></i>
    <a [href]="dataSource && dataSource.whatsapp ? dataSource.whatsapp : '#'" target="_blank">
        <i class="fab fa-whatsapp"></i>
    </a>
</div>

<!-- <div class="go-top"><i class="fas fa-chevron-up"></i><i class="fas fa-chevron-up"></i></div> -->

<!-- Login Modal -->
<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <ng-container *ngIf="signupModalShow$ | async ; let modal">
            <app-signup *ngIf="modal=='signup'"></app-signup>
            <app-quote-form *ngIf="modal=='quote'"></app-quote-form>
            <app-signin *ngIf="modal=='signin'"></app-signin>

        </ng-container>
        <span class="close-btn sidebar-modal-close-btn" id="login-modal-close-btn"><i
               class="flaticon-cross-out"></i></span>
    </div>
</div>