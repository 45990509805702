import { Component, Input, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { GenericCallService } from "src/app/core/generic-call.service";
import { CommonService } from "src/app/core/common.service";
import { Constants } from "src/app/core/constant.service";
import { FirmDetailService } from "src/app/core/firm-detail.service";
import { EnquiryForm } from "src/app/core/enums/validations-enum";
import { Pattern } from "src/app/core/enums/patterns";
import { Router } from "@angular/router";

@Component({
    selector: "app-enquiry-form",
    templateUrl: "./enquiry-form.component.html",
    styleUrls: ["./enquiry-form.component.scss"],
})
export class EnquiryFormComponent implements OnInit {
    form: FormGroup;
    SuccessMessage: string;
    policyTypes: any = [];
    contactNo: string = "";
    validationMessages: any = [];

    @Input() showSuccessMessage = true;
    @Input() showDOBField = true;
    @Input() defaultInsuranceType = 0;

    constructor(
        private formBuilder: FormBuilder,
        private _genericCall: GenericCallService,
        private constants: Constants,
        private commonService: CommonService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.SuccessMessage =
            "Please fill the form and our experts will reply you with a quote very soon";
        this.formInit();
        this.getAttributeTypeList(1);
    }

    formInit() {
        let formModel = {
            Name: new FormControl("", [Validators.required]),
            DOB: new FormControl(null),
            EmailId: new FormControl(null, [
                Validators.pattern(Pattern.EmailAddress),
            ]),
            ContactNo: new FormControl(null, [Validators.required]),
            City: new FormControl(null, [Validators.required]),
            InsuranceType: new FormControl(
                this.defaultInsuranceType > 0
                    ? this.defaultInsuranceType
                    : null,
                [Validators.required]
            ),
            DeviceType: new FormControl("WebSite"),
        };
        this.form = this.formBuilder.group(formModel);
        this.validationMessages = this.commonService.getValidationMessages(
            formModel,
            EnquiryForm
        );
    }
    getAttributeTypeList(Id: number) {
        this._genericCall
            .postMethodWithoutToken(
                this.constants.urlpaths().getAttributeListById + Id,
                {}
            )
            .subscribe((response) => {
                if (response && response.body.data) {
                    this.policyTypes = response.body.data;
                }
            });
    }

    onSubmit(form: FormGroup) {
        debugger;
        if (this.form.valid) {
            this._genericCall
                .postMethodWithoutToken(
                    this.constants.urlpaths().addEditQuote,
                    this.form.value
                )
                .subscribe((response) => {
                    if (response.body.data.isSuccess == 1) {
                        // this.SuccessMessage =
                        //     "Thank you for submitting your enquiry. Our team will contact you soon.";
                        this.resetForm();
                        this.router.navigate(["motor-insurance-thankyou"]);
                    }
                });
        } else {
            this.commonService.validateAllFormFields(this.form);
        }
    }
    resetForm() {
        this.form.setValue({
            Name: null,
            DOB: null,
            EmailId: null,
            ContactNo: null,
            InsuranceType: null,
            DeviceType: "WebSite",
        });
    }
    onChange(event) {
        alert(event.target.value);
    }
}
