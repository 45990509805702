import { Component, OnInit, Output } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthService } from "src/app/core/auth/auth.service";
import { CommonService } from "src/app/core/common.service";
import { FirmDetailService } from "src/app/core/firm-detail.service";
import { GenericCallService } from "src/app/core/generic-call.service";
import { SessionCheck } from "src/app/core/session/sessioncheck.service";

@Component({
    selector: "app-signin",
    templateUrl: "./signin.component.html",
    styleUrls: ["./signin.component.scss"],
})
export class SigninComponent implements OnInit {
    loginForm: FormGroup;
    OTPsend: boolean = false;
    onFormSubmit = false;
    message: string;
    firmDetail: any = [];
    // public showLoader$ = new BehaviorSubject<boolean>(false);
    public showLoader: boolean;

    constructor(
        private authService: AuthService,
        private router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        public commonService: CommonService,
        private _sessionCall: SessionCheck,
        public _firmDetail: FirmDetailService // public commonService: CommonService
    ) {}

    // On ForgotPassword link click
    onForgotPassword() {
        this.router.navigate(["forgot-password"], {
            relativeTo: this.route.parent,
        });
    }
    get(): void {
        this.firmDetail = this._firmDetail.ngOnInit;
    }
    ngOnInit(): void {
        this.get();

        this.OTPsend = false;
        this.showLoader = false;
        // this.showLoader$.asObservable().subscribe(data => this.showLoader=data);
        this.loginForm = this.fb.group(
            {
                password: new FormControl(null),
                otp: new FormControl(null),
                mobileno: new FormControl(null, [Validators.required]),
            },
            { updateOn: "blur" }
        );
        this.firmDetail = this._firmDetail.ngOnInit;
    }

    // On Sing-in click
    onSubmit() {
        this.onFormSubmit = true;
        if (this.loginForm.valid) {
            this.onFormSubmit = false;
            // this.showLoader$.next(!this.showLoader$);
            this.showLoader = !this.showLoader;
            // this.commonService.showLoader = true;

            this.authService
                .OtpSignIn(this.loginForm.value.mobileno)
                .subscribe((response) => {
                    console.log(response.body);
                    if (response.body.data !== null) {
                        this.message = response.body.data.statusMessage;

                        if (this.message === "Please enter OTP to Login.") {
                            this.toastr.success(
                                "OTP sent to your registered mobile No",
                                "Fill OTP to Login"
                            );
                            this.OTPsend = true;
                            this.showLoader = !this.showLoader;

                            // this.commonService.showLoader = false;
                        } else {
                            this.toastr.error(
                                "Invalid User Details.",
                                "Login Error"
                            );
                            // this.commonService.showLoader = false;
                            this.showLoader = !this.showLoader;
                        }
                    } else {
                        this.toastr.error(
                            "Error.",
                            "Internal server error. Please try again"
                        );
                        this.showLoader = !this.showLoader;
                    }
                });
        } else {
            this.toastr.error(
                "Please fill a valid 10 digit mobile no.",
                "Login Error"
            );
        }
        // this.showLoader$.next(!this.showLoader$);
        // this.showLoader = (!this.showLoader);
        // this.commonService.showLoader = false;
    }
    onOTPSubmit() {
        this.onFormSubmit = true;
        // this.showLoader$.next(!this.showLoader$);
        this.showLoader = !this.showLoader;
        console.log(this.loginForm.value);
        this.authService
            .verifyOTPtoSignIn(
                this.loginForm.value.mobileno,
                this.loginForm.value.otp
            )
            .subscribe((response) => {
                //
                // console.log(response.body.data);
                if (
                    response.body.data !== null &&
                    response.body.data.userAuthId
                ) {
                    const userID = response.body.data.userAthId;
                    const tokenID = response.body.tokenKey;
                    if (tokenID != null) {
                        this._sessionCall.setLocalStorage("userID", userID);
                        this._sessionCall.setLocalStorage("token", tokenID);
                        this.toastr.success(
                            "Welcome to the Dashbaord",
                            "Login Successfull"
                        );
                        document
                            .getElementById("login-modal-close-btn")
                            .click(); // to close popup screen
                        this.commonService.userLogin$.next(true);
                        this.router.navigate([""]);
                    }
                    // this.showLoader$.next(!this.showLoader$);
                    this.showLoader = !this.showLoader;
                } else {
                    this.toastr.error("Incorrect OTP.", "Login Error");
                    // this.showLoader$.next(!this.showLoader$);
                    this.showLoader = !this.showLoader;
                }
            });
    }
    // On Signup link click
    onSignup() {
        // this.commonService.signupModal$.next("signup");
    }
    // Send OTP for Sign-in click
}
