import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/core/auth/auth.service";
import { CommonService } from "src/app/core/common.service";
import { Constants } from "src/app/core/constant.service";
import { GenericCallService } from "src/app/core/generic-call.service";
import { SessionCheck } from "src/app/core/session/sessioncheck.service";

@Component({
    selector: "app-quote-form",
    templateUrl: "./quote-form.component.html",
    styleUrls: ["./quote-form.component.scss"],
})
export class QuoteFormComponent implements OnInit {
    form: FormGroup;
    SuccessMessage: string;
    policyTypes: any = [];
    constructor(
        private _genricCall: GenericCallService,
        private authService: AuthService,
        private router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private _sessionCall: SessionCheck,
        private commonService: CommonService,
        private constants: Constants
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group(this.formInit());
        this.getAttributeTypeList(1);
    }
    formInit() {
        return {
            Name: new FormControl("", [Validators.required]),
            DOB: new FormControl(null, [Validators.required]),
            EmailId: new FormControl(null, [Validators.required]),
            ContactNo: new FormControl(null, [Validators.required]),
            InsuranceType: new FormControl(null),
            DeviceType: new FormControl("WebSite"),
        };
    }
    getAttributeTypeList(Id: number) {
        this._genricCall
            .postMethodWithoutToken(
                this.constants.urlpaths().getAttributeListById + Id,
                {}
            )
            .subscribe((response) => {
                if (response && response.body.data) {
                    this.policyTypes = response.body.data;
                }
            });
    }
    onSubmit(form: FormGroup) {
        if (this.form.valid) {
            this._genricCall
                .postMethodWithoutToken(
                    this.constants.urlpaths().addEditQuote,
                    this.form.value
                )
                .subscribe((response) => {
                    if (response.body.data.isSuccess == 1) {
                        this.SuccessMessage =
                            "Thank you for submitting your quotation. Our representative contact you soon.";
                        this.resetForm();
                    }
                });
        }
    }
    resetForm() {
        this.form.setValue({
            Name: null,
            DOB: null,
            EmailId: null,
            ContactNo: null,
            InsuranceType: null,
            DeviceType: "WebSite",
        });
    }
}
