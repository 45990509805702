import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-individualhealth-insurance',
  templateUrl: './individualhealth-insurance.component.html',
  styleUrls: ['./individualhealth-insurance.component.scss']
})
export class IndividualhealthInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
