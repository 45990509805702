<app-header-one></app-header-one>

<div class="page-title-area page-title-travel">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Travel Insurance</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Travel Insurance</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>Travel policy is a multiple-line insurance policy</h3>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Randomised words which don't slightly believable.</p> -->
                       <p>A travel insurance policy is a type of insurance plan that offers financial compensation to the policyholder in case anything goes wrong on the trip. Online travel insurance coverage is helpful during vacations, business trips, and even educational trips. Benefits include compensation for the theft of your money or passport, medical evaluation, delay in checked-in baggage, missed connections, etc.</p>
                        <p>Travel insurance protects you from any unexpected financial losses while travelling, internationally or domestically.  Basic travel insurance plans cover medical expenses, however, comprehensive travel insurance policies also offer coverage for flight delays, lost passports, trip cancellations, personal liability, etc.</p>
                        <p>Right from your first flight to the stay and the return journey, you can ensure a safe and hassle-free trip if you buy travel insurance online.</p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some for.</p> -->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="image text-center">
                        <img src="assets/img/services-image/travel1.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="insurance-details-desc">
            <h3>Six major benefits of perfect travel insurance plan.</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>When you go on a holiday, make sure you have the best travel insurance plan which provides the following features:</p>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i> Journey Coverage</li>
                        <li><i class="fas fa-check"></i>COVID-19 Coverage</li>
                        <li><i class="fas fa-check"></i> Baggage Coverage</li>
                        <!-- <li><i class="fas fa-check"></i> A good Work Environment For work</li> -->
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i> Medical Coverage</li>
                        <li><i class="fas fa-check"></i>Add-On Covers/Optional Covers</li>
                        <li><i class="fas fa-check"></i>Travel Insurance Portability</li>
                        <!-- <li><i class="fas fa-check"></i> A good Work Environment For work</li> -->
                    </ul>
                </div>
            </div>

            <h3>Setting the mood with incense</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Whether you're planning on going solo or with a group of friends or family, Grove Care can help you find an appropriate domestic or international travel insurance policy that meets your needs. You can compare all of your trip insurance options, procure coverage online and travel worry-free.</p>
            <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/travel2.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/travel3.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/travel4.jpg" alt="image">
                    </figure>
                </li>
            </ul>

            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>The right travel insurance policy gives you peace of mind no matter where your travels take you. You'll know where to turn if something goes wrong, with 24/7 emergency assistance from your trip insurance provider.</p>
            <p>It can be hard to know where to begin when searching for a travel insurance policy. An ideal policy offers sufficient coverage at a reasonable price. There are many different variables involved in the quote process and insurance carriers offer a variety of plans and coverage levels to choose from. No one should go through this process uninformed and without an easy way of navigating the world of trip insurance.</p>
            <blockquote class="wp-block-quote">
                <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> -->
                <p>Find travel insurance policies from reliable companies and stay protected on your next trip with Grover Care - it's Travel Insurance Made Easy™.</p>
                <cite>Tom Cruise</cite>
            </blockquote>
            
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>Grover Care helps you compare plans from the world's leading travel insurance providers. Our comparison engine allows you to easily decipher plan benefits and coverage while choosing the right travel insurance policy for your trip. You can quote, compare and buy in just a few minutes and receive your policy confirmation and documents via email instantly after purchase.</p>
            <div class="truz-post-navigation">
                <div class="prev-link-wrapper">
                    <div class="info-prev-link-wrapper">
                        <a routerLink="/health-insurance">
                            <span class="image-prev">
                                <img src="assets/img/services-image/health4.jpg" alt="image">
                                <span class="post-nav-title">Prev</span>
                            </span>
                            <span class="prev-link-info-wrapper">
                                <span class="prev-title">Health Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="next-link-wrapper">
                    <div class="info-next-link-wrapper">
                        <a routerLink="/motor-insurance">
                            <span class="next-link-info-wrapper">
                                <span class="next-title">Motor Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                            <span class="image-next">
                                <img src="assets/img/services-image/motor4.jpg" alt="image">
                                <span class="post-nav-title">Next</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>