import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class VersionControlling {

    version: object[];
    running_Version: any = '3.1.1';

    constructor(public router: Router, private httpService: HttpClient) { }

    Version_Controlling() {
        this.httpService.get('assets/version.json').subscribe(
            data => {
                const res = data[0];
                this.version = res['version'];
                if (this.version[0]['Current_version'] === this.running_Version) { console.log(true) } else {
                    window.location.reload();
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err.message);
            }
        );
    }
}


