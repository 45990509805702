import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-car-insurance",
    templateUrl: "./car-insurance.component.html",
    styleUrls: ["./car-insurance.component.scss"],
})
export class CarInsuranceComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    scrollToSection(sectionName): void {
        const element = document.getElementById(sectionName);
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        }
    }
}
