<app-header-one></app-header-one>

<div class="page-title-area page-title-privacy">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Privacy Policy</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">

        <div class="insurance-details-desc">
            <h3>Privacy Policy</h3>
            <p>Grover Insurance Broker Private Limited as mentioned in this privacy policy (‘Privacy Policy’) (hereinafter referred to as "Grovercare”) is concerned about the privacy of the data and information of the users accessing and availing services on any  websites including without limitation ‘www.grovercare.com’, mobile sites, mobile applications or chrome extension or plugins thereof accessible through various internet enabled smart devices(individually and collectively referred to as "Platform") or otherwise doing business with Grovercare.This Privacy Policy applies to Grovercare and helps you understand how we collect, use, store, process, transfer, share and otherwise deal with and protect all your information when you visit any of our Platform and avail our services or even otherwise visit the Platform. THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF ELECTRONIC CONTRACT IN TERMS OF INDIAN INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER (AS AMENDED FROM TIME TO TIME) AND DOES NOT REQUIRE ANY PHYSICAL SIGNATURE OR SEAL.The term "We"/ "Us" / "Our" used in this document refer to Grovercare and "You" / "Your" / "Yourself" refer to the users, who visit or access or use (collectively "usage") Platform.</p>
            <h3>Acknowledgment</h3>
            <p>PLEASE READ THIS PRIVACY POLICY CAREFULLY. YOU INDICATE THAT YOU UNDERSTAND, AGREE AND CONSENT TO THIS PRIVACY POLICY.HENCE BY VISITING OUR PLATFORM OR BY AVAILING ANY OF OUR SERVICE, YOU HEREBY GIVE YOUR UNCONDITIONAL CONSENT OR AGREEMENT TO GROVERCARE AS PROVIDED UNDER SECTION 43A AND SECTION 72A OF INDIAN INFORMATION TECHNOLOGY ACT, 2000 FOR COLLECTION, USE, STORAGE, PROCESSING, SHARING AND TRANSFER AND DISCLOSURE OF YOUR INFORMATION.

                YOU ACKNOWLEDGE THAT YOU HAVE ALL LEGAL RIGHTS AND LAWFUL AUTHORITY TO SHARE THE INFORMATION WITH US AND FURTHER ACKNOWLEDGE THAT BY COLLECTING, SHARING, PROCESSING AND TRANSFERRING INFORMATION PROVIDED BY YOU, SHALL NOT CAUSE ANY LOSS OR WRONFUL GAIN TO YOU OR ANY OTHER PERSON.
                
                IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS / USE OUR PLATFORM OR AVAIL ANY OF OUR SERVICE ON OUR PLATFORM.</p>
               <h3>Topics Covered</h3>
                <p>Information may be collected through various ways such through the use of cookies. Grovercare Insurance may store temporary or permanent "cookies" on your computer. "Cookies" are files situated on your mobile/ computer/device"s hard disk that assist us in providing services. Further, there are certain features of the Website that are available only through the use of a "cookie". These "cookies" may be used whether you register on the Website or not. You can erase or choose to block these cookies from your computer. You can configure your computer’s browser to alert you when we attempt to send you a cookie with an option to accept or refuse the cookie. If you have turned cookies off, you may be prevented from using certain features of the Website.</p>
                <p>Your Information will mostly be stored in electronic form however certain data may be stored in physical form.</p>
                
                <h3>Controller of Personal Information</h3>
                <p>GroverCare will be the controller of Your Personal Information provided by You or otherwise collected by Us. Your data controller is responsible for the collection, use, disclosure, retention, and protection of your Personal Information in accordance with its privacy standards as well as any applicable laws.</p>
                
             <h3>Communication</h3>
              <p> We may contact You through email, mobile phone, notices posted on Our Platform and other ways including but not limited to text messages and push notifications or any other mode.We may send You messages or emails about the availability of Our services, notifications, promotional messages, security, or other service - related issues.You hereby override your NDNC registration.You may change Your communication preferences at any time.</p>
                
              <h3>Manner of storage of Your Information</h3>
              <p>Your Information will mostly be stored in electronic form however certain data may be stored in physical form. We shall store, collect, use and process Your Information within Republic of India subject to compliance under applicable laws.We may enter into agreements with third parties within India to store or process Your Information and such third parties may have their own security measures to safeguard Your Information which security standards as comparable with good industry practices.</p>
              
              <h3>Security Measures and Force Majeure</h3>
               <p>We takes utmost care to secure Your Information from unauthorised access, usage or disclosure or alteration. We take appropriate steps and security measures to safeguard Your Information and make sure that Your Information is secured as mandated by law.For this purpose we adopt reasonable security practices and procedures, in line with the industry standard, to include, technical, operational, managerial and physical security controls in order to protect your personal information from unauthorized access, or disclosure while it is under our control.</p>
               <p>While We protect Your Personal information as per industry standards, You acknowledge that the internet or computer networks are not fully secure and that We cannot provide any absolute assurance regarding the security of Your Personal information. Therefore You acknowledge that You shall not hold responsible  in any manner whatsoever for loss of Your Information, or any other data, which You shares with Us or which We have with Us if such loss or any damage caused to You because of Force Majeure events.You further acknowledge that any information(including your personal information) transmitted to Us or from Your use of Platform will therefore be at Your own risk.</p>
               <p>It is clarified that Force Majeure events includes any event which is beyond the reasonable control of the Grovercare which may include but not limited to sabotage, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, unauthorised access to computer, computer system or computer network, computer crashes, breach of security and encryption.</p>
    
              <h3>Change in terms of Privacy Policy</h3>
              <p>We reserves the right to amend or modify this Privacy Policy at any time, as and when the need arises. We request you to regularly check this Privacy Policy from time to time to keep you apprise of changes made.Your continued use of the Platform gives your unconditional acceptance to such change in terms of Privacy Policy.</p>
              <p>Particulars of corporate entity, Data Protection Officer/Grievance Officer pursuant to the provisions of Companies Act, 2013 and The Indian Information Technology Act, 2000 and rules made and applicable laws thereunder:</p>
              <p>If you have any concern related to this Privacy Policy or collection, storage, retention or disclosure of Your Information under the terms of this Privacy Policy or Terms of Use or any other terms and conditions of Girnar or its other business entities including any queries or grievances, You can connect us through our mobile no or email.</p>    
    </div>
    </div>
</section>

