import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-about",
    templateUrl: "./about.component.html",
    styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
    startYear: number = 1958;
    noOfYearExp: number;
    constructor() {}

    ngOnInit(): void {
        
        const year: number = new Date().getFullYear();
        this.noOfYearExp = year - this.startYear;
    }
}
