<!-- <app-header-one></app-header-one> -->

<section class="row col-lg-12 col-md-12">
<section class="faq-area  row col-lg-6 col-md-6 margin10px ">
    <div class="container cardbackground row col-lg-12 col-md-12 height" >
        <button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample1" aria-expanded="false" aria-controls="collapseExample">
            1: Which vehicle insurance plan should I purchase- comprehensive insurance plan or third-party insurance plan only?
        </button>
      
        <div class="collapse ansColor" id="collapseExample1" flag="up">
          <div class="card card-body ansColor">
            <p>Having a third-party motor insurance plan is compulsory for all automobiles plying on the Indian roads. This insurance plan provides coverage for injuries or damages caused to other people. The beneficiary is third-party only. The prudent way to get coverage for the losses or damages caused to the insured vehicle is buying a comprehensive motor insurance plan. It provides the coverage for third-party liability along with own damage caused to the insured automobile, such as bikes, scooters, cars, trucks, etc.</p>
          </div>
        </div>
   </div> 
   <div class="container cardbackground row col-lg-12 col-md-12 height">
    <button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample">
        2: How is the motor insurance premium calculated?
    </button>
  
    <div class="collapse ansColor" id="collapseExample2">
      <div class="card card-body ansColor">
        <p>Motor insurance premium is calculated basis various factors, such as IDV, deductibles, seating capacity, cubic capacity, previous insurance history etc. For comprehensive insurance plans, premium charges vary insurance provider per insurance provider on the basis of the coverage provided. Do compare insurance premium on websites like Policybazaar.com so that you get the best quote. Third-party premium amounts are decided by IRDA.</p>
      </div>
    </div>
</div>
<div class="container cardbackground row col-lg-12 col-md-12 height">
<button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample3" aria-expanded="false" aria-controls="collapseExample">
    3: What should I do in case of an accident?
</button>

<div class="collapse ansColor" id="collapseExample3">
  <div class="card card-body ansColor">
    <p>In case your vehicle meets with an accident, you should notify the police and take spot pictures. Click pictures of your vehicle as well as the other vehicle from all angles and focus on the damages. Exchange crucial information from the driver of the other vehicle, including policy number, motor insurance provider, name, phone number, etc. Contact your insurer and file a claim in order to get your damages covered. Keep your car keys and belongings safe.</p>
  </div>
</div>
</div>

<div class="container cardbackground row col-lg-12 col-md-12 height">
<button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample4" aria-expanded="false" aria-controls="collapseExample">
    4: What is the period of the motor insurance policy?
</button>

<div class="collapse ansColor" id="collapseExample4">
  <div class="card card-body ansColor">
    <p> Generally, a vehicle insurance policy is valid for one year and it has to be renewed prior to the due date in order to avoid any lapse in the policy. For a smoother insurance experience, always pay the motor insurance premium before the due date. If your policy has lapsed then your automobile would undergo an inspection. In addition to that, if a comprehensive insurance policy remains lapse for a time period of more than 90 days, the benefit of “No Claim Bonus” isn’t provided.</p>
  </div>
</div>
</div>

<div class="container cardbackground row col-lg-12 col-md-12 height">
<button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample5" aria-expanded="false" aria-controls="collapseExample">
    5: What is "No Claim Bonus" in Motor Insurance?
</button>

<div class="collapse ansColor" id="collapseExample5">
  <div class="card card-body ansColor">
    <p> “No Claim Bonus” is the benefit accumulated to a policyholder in case he hasn’t filed any claim throughout the policy period. According to the present Indian norms, it varies from 20-50 per cent for the comprehensive insurance plan. NCB isn’t applicable for a third-party motor insurance plan. If a claim is filed, then the “No Claim Bonus” is lost for that policy period. NCB is provided to the policyholder and not to the insured car, two wheelers or commercial vehicle. </p>
  </div>
</div>
</div>

<div class="container cardbackground row col-lg-12 col-md-12 height">
<button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample6" aria-expanded="false" aria-controls="collapseExample">
    6: In case I change my motor insurance provider, would my No Claim Bonus be migrated?
</button>

<div class="collapse ansColor" id="collapseExample6">
  <div class="card card-body ansColor">
    <p> Yes, you surely can avail the NCB if you change your motor insurance provider at the time of renewing the policy. All you would need to do is producing proof of the earned NCB from your current insurance provider. You can produce the original copy of your expiring policy and a certification that you haven’t filed any claim for the (expiring) insurance plan. A renewal notice or a letter stating that you’re entitled to the NCB from your previous insurance provider can be a proof for this.</p>
  </div>
</div>
</div>

<div class="container cardbackground row col-lg-12 col-md-12 height">
<button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample7" aria-expanded="false" aria-controls="collapseExample">
    7: Is there any kind of discounts that would reduce my premium?
</button>

<div class="collapse ansColor" id="collapseExample7">
  <div class="card card-body ansColor">
    <p>Apart from the NCB, there are a few discounts available on the own damage premium :</p>
    <ul class="data_ul">
        <li>Having a membership of the Automobile Association of India</li>
        <li>Private cars that are certified by the Vintage and Classic Car Club of India</li>
        <li>Installation of any anti-theft equipment that is approved by the Automobile Research Association of India (ARAI), Pune and is acknowledged by AAI.</li>
        <li>There are concessions offered specifically for modified or designed automobiles for the visually challenged, physically challenged or mentally challenged individuals that are aptly certified in the Registration Certificate by the concerned RTA.</li>
        <li>When you select an additional voluntary deductible, your motor insurance provider offers you attractive discounts.</li>
<li>As per the Liability Only section, there are discounts available for decreasing the third-party property damage from Rs. 7,50,000 to Rs. 6,000.</li>
    </ul>
  </div>
</div>
</div>

<div class="container cardbackground row col-lg-12 col-md-12 height">
<button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample8" aria-expanded="false" aria-controls="collapseExample">
    8: Is service tax applicable on the motor insurance premium?
</button>

<div class="collapse ansColor" id="collapseExample8">
  <div class="card card-body ansColor">
    <p> Yes, service tax is levied on motor insurance premiums as per the prevailing law rule(s).</p>
  </div>
</div>
</div>

<div class="container cardbackground row col-lg-12 col-md-12 height">
<button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample9" aria-expanded="false" aria-controls="collapseExample">
    9: What is a deductible in motor insurance?
</button>

<div class="collapse ansColor" id="collapseExample9">
  <div class="card card-body ansColor">
    <p>The deductible is the amount of which the claim would be payable. Generally, a normal standard or compulsory deductible for most of the automobiles range from Rs. 50 for the two wheeler automobile, including bikes and scooters to Rs. 1000 and Rs. 2,000 for private cars and commercial vehicle, including trucks that enhance according to the carrying capacity or cubic capacity of the automobile. Though, there could be cases where the insurance provider might enforce extra deductible which depends on the vehicle’s age or if the claims frequency is comparatively higher. </p>
  </div>
</div>
</div>
<div class="container cardbackground row col-lg-12 col-md-12 height">
<button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample10" aria-expanded="false" aria-controls="collapseExample">
    10: What is the process for getting the changes done in the policy?
</button>

<div class="collapse ansColor" id="collapseExample10">
  <div class="card card-body ansColor">
    <p> In case there are any changes that need to be made in the policy such as address change or any particular changes regarding the automobile or its usage, it could be done by an endorsement by the motor insurance provider. You need to submit a letter to your insurance provider along with the proof of the changes so that you could obtain the endorsement. There are a few endorsements that might charge an extra premium from you.</p>
  </div>
</div>
</div>
<div class="container cardbackground row col-lg-12 col-md-12 height">
<button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample11" aria-expanded="false" aria-controls="collapseExample">
    11: If I am driving my car in a specific city, how the premium rate would be applied?
</button>

<div class="collapse ansColor" id="collapseExample11">
  <div class="card card-body ansColor">
    <p>For the sole purpose of applying the motor insurance premium rate, the specific location where the automobile is registered is considered. Don’t confuse the place of registration with the place where the automobile is used. For instance, if your automobile is registered in Chennai, the applicable charges for Zone A would be charged. Even if you shift to another town or city, the same charges would be applied. Likewise, if an automobile is registered in a town, Zone B premium charges are applicable. Later on, in case the vehicle own moves to a metro city, he would be charged the rate of Zone B only. </p>
  </div>
</div>
</div>
<div class="container cardbackground row col-lg-12 col-md-12 height">
<button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample12" aria-expanded="false" aria-controls="collapseExample">
    12: In case I fit LPG or CNG kit in my automobile, is it necessary to update the insurance provider about that?
</button>

<div class="collapse ansColor" id="collapseExample12">
  <div class="card card-body ansColor">
    <p> If an LPG or CNG kit is installed in your automobile, you must inform the Road Transport Authority’s office, from where the automobile was registered so that they can modify the necessary changes in the registration certificate of the automobile. The motor insurance provider should be informed as well so that it can provide the coverage to the kit on the payment of additional premium as per the kit’s value under the Own Damage Section.</p>
  </div>
</div>
</div>
</section>
<section class="faq-area   row col-lg-6 col-md-6">

<div class="container cardbackground row col-lg-12 col-md-12 height">
    <button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample13" aria-expanded="false" aria-controls="collapseExample">
        13: Can I get my motor insurance transferred to the buyer of my automobile?
    </button>
    
    <div class="collapse ansColor" id="collapseExample13">
      <div class="card card-body ansColor">
        <p>Yes, motor insurance is transferable to the purchaser of the automobile. All you have to do is inform in writing about the transfer to its insurance provider. The original owner of the car/ two wheeler needs to fill out a new proposal form. A nominal fee is charged for the insurance transfer along with the recovery of No Claim Bonus from the transfer date till the policy expires on a pro-rata basis. Keep in mind that ownership transfer in comprehensive insurance plans has to be updated within 14 days from the date of transfer. If the original buyer fails to do so, no claim would be payable regarding the Own Damage. </p>
      </div>
    </div>
    </div>
    <div class="container cardbackground row col-lg-12 col-md-12 height">
    <button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample14" aria-expanded="false" aria-controls="collapseExample">
        14: If I lose my motor insurance policy, would I get a duplicate copy?
    </button>
    
    <div class="collapse ansColor" id="collapseExample14">
      <div class="card card-body ansColor">
        <p>Yes. You have to approach the office of your motor insurance provider from where you have purchased your policy and submit a request in writing. A nominal fee would be charged for the issue of a duplicate policy. If you have purchased a motor insurance online, you can also download a duplicate copy of your policy from the insurer’s website or from Policybazaar.com. </p>
      </div>
    </div>
    </div>
    <div class="container cardbackground row col-lg-12 col-md-12 height">
    <button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample15" aria-expanded="false" aria-controls="collapseExample">
        15: What happens if I don’t buy motor insurance for my vehicle?
    </button>
    
    <div class="collapse ansColor" id="collapseExample15">
      <div class="card card-body ansColor">
        <p> All cars, bikes, scooters and trucks plying on Indian roads need to have a valid motor insurance policy. If you don’t buy motor insurance for your vehicle, you will defy the Motor Vehicles Act, 1988 and will be liable to pay a fine of Rs. 2000 or get jailed for up to three months.</p>
      </div>
    </div>
    </div>
    <div class="container cardbackground row col-lg-12 col-md-12 height">
    <button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample16" aria-expanded="false" aria-controls="collapseExample">
        16: Is buying motor insurance policy easy?
    </button>
    
    <div class="collapse ansColor" id="collapseExample16">
      <div class="card card-body ansColor">
        <p>Yes. It is very easy to buy motor insurance for your vehicle, be it a two wheeler, a car or a commercial vehicle. You can get in touch with an insurance agent or visit the branch of the insurance provider. All insurance companies in India also allow vehicle owners to purchase insurance online within a few minutes without any hassles. </p>
      </div>
    </div>
    </div>
    <div class="container cardbackground row col-lg-12 col-md-12 height">
    <button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample17" aria-expanded="false" aria-controls="collapseExample">
        17: Is it beneficial to buy a motor insurance policy online?
    </button>
    
    <div class="collapse ansColor" id="collapseExample17">
      <div class="card card-body ansColor">
        <p> Yes. It is extremely beneficial to purchase motor insurance online as it is a faster process, more convenient and paperless. Some insurers also offer discounts to vehicle owners on buying insurance online. Besides, the policy can be issued within a few minutes without the need for you to step out of your house.</p>
      </div>
    </div>
    </div>
    <div class="container cardbackground row col-lg-12 col-md-12 height">
    <button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample18" aria-expanded="false" aria-controls="collapseExample">
        18: Can I renew my motor insurance policy online?
    </button>
    
    <div class="collapse ansColor" id="collapseExample18">
      <div class="card card-body ansColor">
        <p>Yes. You can renew your motor insurance policy online by visiting your insurer’s official website or by going to an insurance broker website, like Policybazaar.com. </p>
      </div>
    </div>
    </div>
    <div class="container cardbackground row col-lg-12 col-md-12 height">
    <button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample19" aria-expanded="false" aria-controls="collapseExample">
        19: Is it possible for me to cancel my motor insurance claim?
    </button>
    
    <div class="collapse ansColor" id="collapseExample19">
      <div class="card card-body ansColor">
        <p>Yes, you can cancel your motor insurance claim by contacting your insurance provider by calling on their customer care number or by sending an email requesting them to cancel your claim. </p>
      </div>
    </div>
    </div>
    <div class="container cardbackground row col-lg-12 col-md-12 height">
    <button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample20" aria-expanded="false" aria-controls="collapseExample">
        20: What is a Motor Insurance Cover Note?
    </button>
    
    <div class="collapse ansColor" id="collapseExample20">
      <div class="card card-body ansColor">
        <p>A motor insurance cover note is a certificate of insurance that is issued by an insurance company prior to the actual policy document. This document is issued after the vehicle owner submits a filled-in proposal form and pays the insurance premium. The validity of the cover note is 60 days from the date of its issuance. Hence, it is important for the insurance company to issue a motor insurance policy document to the vehicle owner before the cover note expires. </p>
      </div>
    </div>
    </div>
    <div class="container cardbackground row col-lg-12 col-md-12 height">
    <button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample21" aria-expanded="false" aria-controls="collapseExample">
        21: When is pre-policy vehicle inspection carried out?
    </button>
    
    <div class="collapse ansColor" id="collapseExample21">
      <div class="card card-body ansColor">
        <p>A motor insurance company conducts pre-policy inspection of cars, bikes, scooters or trucks if: </p>
        <ul class="data_ul">
            <li>There is a break-in-insurance</li>
            <li>Third party insurance has to be converted to comprehensive insurance</li>
            <li>Imported cars or bikes have to insured</li>
            <li>The fresh payment has been received after a bounced cheque</li>
            </ul>
      </div>
    </div>
    </div>
    
    <div class="container cardbackground row col-lg-12 col-md-12 height">
    <button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample22" aria-expanded="false" aria-controls="collapseExample">
      22: What do you mean by electronic/ electrical accessories in cars?
    </button>
    
    <div class="collapse ansColor" id="collapseExample22">
      <div class="card card-body ansColor">
        <p> Electronic or electrical accessories include those electronic/ electric items fitted in your car that was not provided by the vehicle manufacturer at the time of purchase. For example, if you added an LCD screen in your car after buying it, it will be considered as an electronic/ electric accessory. These accessories are covered only if the vehicle owner opts for its coverage while buying the motor insurance policy. They can be insured at an additional premium of 4% on the kit value of such accessories. </p>
       
      </div>
    </div>
    </div>
    
    <div class="container cardbackground row col-lg-12 col-md-12 height">
    <button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample23" aria-expanded="false" aria-controls="collapseExample">
        23: Who can get personal accident cover in case of cars?
    </button>
    
    <div class="collapse ansColor" id="collapseExample23">
      <div class="card card-body ansColor">
        <p> You can buy personal accident covers for the following people under your motor insurance policy: </p>
        <ul class="data_ul">
            <li>Owner-driver</li>
            <li>Passengers (both named and unnamed occupants)</li>
            <li>Paid driver</li>
            </ul>
      </div>
    </div>
    </div>
    
    <div class="container cardbackground row col-lg-12 col-md-12 height">
    <button class="btn btn-secondary QusetionColor" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample24" aria-expanded="false" aria-controls="collapseExample">
        24: What happens if I sell my vehicle?
    </button>
    
    <div class="collapse ansColor" id="collapseExample24">
      <div class="card card-body ansColor">
        <p> If you sell your two wheeler, four wheeler or commercial vehicle, the ongoing motor insurance policy will have to be transferred to the name of the buyer. The buyer should apply for the insurance transfer within 14 days of vehicle sale. You can also transfer your policy to another vehicle of yours and then the buyer would have to purchase a new policy for the sold vehicle. </p>
       
      </div>
    </div>
    </div>

</section>
</section>



