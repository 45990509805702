import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/core/auth/auth.service";
import { CommonService } from "src/app/core/common.service";
import { GenericCallService } from "src/app/core/generic-call.service";
import { SessionCheck } from "src/app/core/session/sessioncheck.service";

@Component({
    selector: "app-signup",
    templateUrl: "./signup.component.html",
    styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
    signupForm: FormGroup;
    onFormSubmit = false;
    message: string = undefined;
    OTPsend: boolean = false;
    Userid: any;
    constructor(
        private genericService: GenericCallService,
        private authService: AuthService,
        private router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private _sessionCall: SessionCheck,
        private commonService: CommonService
    ) {}

    // On ForgotPassword link click
    onForgotPassword() {
        this.router.navigate(["forgot-password"], {
            relativeTo: this.route.parent,
        });
    }

    ngOnInit(): void {
        this.signupForm = this.fb.group(
            {
                name: new FormControl("", [Validators.required]),
                mobileno: new FormControl(null, [Validators.required]),
                email: new FormControl(null, [Validators.required]),
                dob: new FormControl(null, [Validators.required]),
                otp: new FormControl(null),
            },
            { updateOn: "blur" }
        );
    }

    // On Sing-in click
    onSubmit() {
        this.onFormSubmit = true;
        if (this.signupForm.valid) {
            this.onFormSubmit = false;

            this.authService
                .signupUser(this.signupForm.value)
                .subscribe((response) => {
                    this.message = response.body.statusMessage;
                    console.log(response.body);
                    if (this.message == "Record inserted successfully") {
                        this.Userid = response.body.data.userId;
                        this.OTPsend = true;
                    } else {
                        console.log("Error");
                    }
                });
        }
    }
    onOTPSubmit() {
        this.onFormSubmit = true;
        if (this.signupForm.valid) {
            let param = {
                ...this.signupForm.value,
                Userid: this.Userid,
            };
            this.onFormSubmit = false;
            this.authService.signupUser(param).subscribe((response) => {
                this.message = response.body.statusMessage;

                if (this.message == "OTP verified") {
                    const otp = this.signupForm.get("otp");
                    otp.removeValidators([Validators.required]);

                    const tokenID = response.body.tokenKey;
                    if (tokenID !== null) {
                        //const toArray = tokenID.split(':');
                        this.Userid = response.body.data.userId;
                        // this.token = toArray[0];
                        // const tokenExpiry = response.headers.get('TokenExpiry');

                        if (
                            tokenID != null &&
                            this.Userid != null
                            // &&
                            //  tokenExpiry != null
                        ) {
                            this._sessionCall.setLocalStorage(
                                "userID",
                                this.Userid
                            );
                            this._sessionCall.setLocalStorage("token", tokenID);
                            this.commonService.userLogin$.next(true);
                            document
                                .getElementById("login-modal-close-btn")
                                .click();

                            this.router.navigate([""]);
                        }
                    } else {
                        console.log("Error");
                    }
                }
            });
        }
    }
    //  this.OTPsend = true;
}
