<app-header-one></app-header-one>

<div class="page-title-area page-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="color:black">My Policies</h2>
                    <ul>
                        <li><a routerLink="/" style="color:black">Home</a></li>
                        <li style="color:black">My Policies</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
        <div class="col-lg-8 col-md-8">
            <div class="tab quote-list-tab">
                <div class="tab_content">
                    <div class="tabs_item">
                        
                        <h6>Active Policies</h6><br>
                        <div class="card rounded w-100">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-2">
                                        <span class="badge rounded-pill bg-primary">Car Insurance</span>
                                    </div>
                                    <div class="col-md-5">
                                        <p>Policy No: 211200/31/2022/248011</p>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-md-2">
                                        <img src="assets/img/partner-image/oriental.png" alt="image"><p>Zero Dep</p>
                                    </div>
                                    <div class="col-md-2">
                                        <p>Reg. No. PB10GS1761</p>
                                    </div>
                                    <div class="col-md-2">
                                        <p>Premium 8,607(Yearly)</p>
                                    </div>
                                    <div class="col-md-2">
                                        <p>Status Expiring Soon</p>
                                    </div>
                                    <div class="col-md-4 d-flex justify-content-end">
                                        <div class="">
                                        <span class="text-danger">Renew Policy</span><br>
                                       <button class="btn btn-outline-success">See Details</button>
                                       </div>  
                                    </div>
                                </div>
                                
                            </div>

                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-md-4">
                                    <span class="badge text-bg-info">1 Nominee &nbsp;<span class="badge text-bg-primary">Varsha</span></span>
                                </div>
                                <div class="col-md-8">
                                    <a href="#" class="card-link">Change Required in Policy</a>
                                    <a href="#" class="card-link">File Claim</a>
                                    <a href="#" class="card-link">Get Policy Copy</a>
                                    <a href="#" class="card-link">Chat</a>
                                </div>
                            </div>
                            
                                  
                                    <!-- <div class="card-body">
                                        
                                </div> -->
                                    <!-- <div class="col-md-4">
                                       <a href="#"><p> Changes required in policy</p></a>
                                    </div>
                                    <div class="col-md-2">
                                        <a href="#"><p>File Claim</p></a>
                                    </div>
                                    <div class="col-md-3">
                                        <a href="#"><p>Get Policy copy</p></a>
                                    </div>
                                    <div class="col-md-1">
                                        <a href="#"><p>chat</p></a>
                                    </div> -->
                                 </div>
                          </div>
                          
                          <br>
                          <div class="card border border-success rounded w-100">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-2">
                                        <span class="badge rounded-pill bg-primary">Car Insurance</span>
                                    </div>
                                    <div class="col-md-10">
                                        <h6>What is TP and OD Cover?</h6>
                                        <p><strong>Third-Party Cover:</strong>Mandatory by law,this covers the cost of damage your vehicle may cause to another person or property.</p>
                                        <p><strong>Third-Party Cover:</strong>This Covers the cost of damage to your own vehicle.</p>
                                    </div>
                                </div>
                                </div>
                                </div>

                                <br>
                                <div class="card  rounded w-100">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <span class="badge rounded-pill bg-primary">Personal Accident Insurance</span>
                                            </div>
                                            <div class="col-md-4">
                                                <p>Policy No: PBC/000000287005</p>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-2">
                                                <img src="assets/img/partner-image/oriental.png" alt="image"><p>Cumpulsory</p>
                                            </div>
                                            <div class="col-md-2">
                                                <p>Cover 15,00,00</p>
                                            </div>
                                            <div class="col-md-2">
                                                <p>Premium 8,607(Yearly)</p>
                                            </div>
                                            <div class="col-md-2">
                                                <p>Status Policy Issued</p>
                                            </div>
                                            <div class="col-md-4 d-flex justify-content-end">
                                                <div class="">
                                                <!-- <span class="text-danger">Renew Policy</span><br> -->
                                               <button class="btn btn-outline-success">See Details</button>
                                               </div>  
                                            </div>
                                        </div>
                                        </div>
                                        </div>



                        
                    </div>




                </div>
            </div>
        </div>
    </div>
    </div>
</section>


