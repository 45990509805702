<app-header-one></app-header-one>

<div class="page-title-area page-title-health">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Health Insurance</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Health Insurance</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>Health policy is a multiple-line insurance policy</h3>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Randomised words which don't slightly believable.</p> -->
                        <p>Health insurance provides medical coverage to the insured against any emergency and planned medical expenses. It provides financial assistance to cover the cost of hospitalization, surgeries, day care procedures, etc. A health insurance plan offers cashless treatment or reimburses the medical treatment costs of the insured.</p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p> -->
                        <p>Health Insurance is a contract between the policyholder and the insurer where the health insurance company provides financial coverage to the insured up to the sum insured limit. It offers medical coverage for healthcare expenses incurred on hospitalization along with multiple benefits, like pre & post-hospitalization cover, medical check-ups, cashless facility, etc.</p>
                        <p>An insurer under the health insurance policy provides coverage for hospitalisation, health check-ups, ambulance charges, etc., in accordance with the amount insured by the policyholder.</p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some for.</p> -->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="image text-center">
                        <img src="assets/img/services-image/health1.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="insurance-details-desc">
            <!-- <h3>Four major elements that we offer:</h3> -->
            <h3>Key Factors To Consider Before Buying Health Insurance Plans</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
                <p>Medical inflation is increasing every day making treatments expensive. If you get hospitalized for a critical illness or lifestyle disease, you may end up losing all your savings. The only way to afford quality medical treatment during a health emergency is by buying a health insurance policy. Take a look at some of the top reasons to buy a health insurance plan below:</p>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Beat Medical Inflation</li>
                        <li><i class="fas fa-check"></i>Afford Quality Medical Treatment</li>
                        <li><i class="fas fa-check"></i>Fight Lifestyle Diseases</li>
                        <li><i class="fas fa-check"></i>Protect Your Savings</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Avail Cashless Hospitalization Facility</li>
                        <li><i class="fas fa-check"></i>Get Tax Benefits</li>
                        <li><i class="fas fa-check"></i>Ensure Peace of Mind</li>
                        <li><i class="fas fa-check"></i>Free Medical Check-Up</li>
                    </ul>
                </div>
            </div>

            <h3>Setting the mood with incense</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>There are several medical health insurance plans in India that you can choose from, at the time of policy purchase. These are individual health insurance plans, family floater health insurance plans, senior citizen health insurance plans, critical illness insurance plans, maternity insurance plans, hospital daily cash etc.If you have a big family or one with senior parents, then an individual health insurance policy is recommended as a family floater policy might not be sufficient for you. You can also buy a separate senior citizen health insurance policy for parents in this case.</p>
            <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/health2.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/health3.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/health4.jpg" alt="image">
                    </figure>
                </li>
            </ul>

            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Also, once you decide which health insurance policy to buy, you can also add add-on covers to it for extensive coverage. It is not mandatory to buy add-on covers but you can include them in your policy voluntarily at the time of policy purchase, by paying an extra premium. Some popular add-ons offered under health insurance plans are room rent waiver, hospital daily cash, second e-opinion, critical illness cover, air ambulance cover, etc.</p>
            <blockquote class="wp-block-quote">
                <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> -->
                <p>Good health is not something we can buy. However, it can be an extremely valuable savings account.</p>
                <cite>Tom Cruise</cite>
            </blockquote>
            
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Buying health insurance can be easy if you approach the right channel. Having said this, Grover Care can be a good platform for choosing the right insurance policy. Grover Care helps you to compare numerous health insurance plans and zero down on the plan that best fulfills your needs. Moreover, the post-sale services are extended to the customers online as well even at the time of medical insurance claim.</p>
            <div class="truz-post-navigation">
                <div class="prev-link-wrapper">
                    <div class="info-prev-link-wrapper">
                        <a routerLink="/home-insurance">
                            <span class="image-prev">
                                <img src="assets/img/services-image/home4.jpg" alt="image">
                                <span class="post-nav-title">Prev</span>
                            </span>
                            <span class="prev-link-info-wrapper">
                                <span class="prev-title">Home Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="next-link-wrapper">
                    <div class="info-next-link-wrapper">
                        <a routerLink="/travel-insurance">
                            <span class="next-link-info-wrapper">
                                <span class="next-title">Travel Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                            <span class="image-next">
                                <img src="assets/img/services-image/travel4.jpg" alt="image">
                                <span class="post-nav-title">Next</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>