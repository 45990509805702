<app-header-one></app-header-one>

<div class="page-title-area page-title-our-solutions">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Our Solutions</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Our Solutions</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">

        <div class="insurance-details-desc">
            <h3>Our Solutions</h3>
            <p>Our teams, equipped with our range of specialised services, provide accurate and relevant information that will help you make smarter decisions, faster and more consistently across markets.</p>
            <h3>Services</h3>
            <p>Grover Care is one stop solution to all your insurance needs- Life,Health, General, Motor, Terms, etc. We firmly believe in maintaining transparency with customers.</p>
    </div>

    <div class="about-inner-area">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="about-text-box">
                    <h3>Our History</h3>
                    <p>Grover care is pioneer start up of Grover group. this group belongs to logistics and automobiles industry from 1958.</p>                        
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="about-text-box">
                    <h3>Our Mission</h3>
                    <p>To safeguard all Indian citizen by providing them insurance solution for their life, health and belongings</p>                        
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="about-text-box">
                    <h3>24X7 Support</h3>
                    <p>At the same time, customers who have bought their policies from Grover Care can reach out to their insurance
                        experts to
                        seek assistance.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="about-text-box">
                    <h3>Our Commitment</h3>
                    <p>We are committed to bring our wealth of knowledge to every individual, every corporate, and every business to safeguard
                    their future and ensure their growth.
                    </p>
                </div>
            </div>
           
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="about-text-box">
                    <h3>Service With Love</h3>
                    <p>We always focused on clients requirements and provide them best solutions. Grover Care Guarantees claim support for your family in those difficult times.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="about-text-box">
                    <h3>Our Solutions</h3>
                    <p>Grover Care is one stop solution to all your insurance needs- Life,Health, General, Motor, Terms, etc. We firmly believe
                    in maintaining transparency with customers.
                    </p>
                </div>
            </div>
        </div>
    </div>


    </div>
</section>



