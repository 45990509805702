import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { SessionCheck } from "../session/sessioncheck.service";
import { GenericCallService } from "../generic-call.service";

import { ToastrService } from "ngx-toastr";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    token: string;
    userID = 0;
    constructor(
        private _genricCall: GenericCallService,
        private _sessionCall: SessionCheck,
        private router: Router,
        private toastr: ToastrService
    ) {}

    signupUser(param: any) {
        // your code for signing up the new user
        const _spUrl = "user/webregister";
        return this._genricCall.postMethodWithoutToken(_spUrl, param);
    }
    OtpSignIn(mobileNo: string) {
        // your code for signing up the new user
        const param = {
            Username: mobileNo,
        };
        const _spUrl = "AuthenticationWebApi/OTPBasedLogin";
        return this._genricCall.postMethodWithoutToken(_spUrl, param);
    }
    otpForSignIn(mobileNo: string) {
        const param = {
            Username: mobileNo,
        };
        const _spUrl = "AuthenticationWebApi/OTPBasedLogin";
        try {
            this._genricCall
                .postMethodWithoutToken(_spUrl, param)
                .subscribe((response) => {
                    const header = response.headers;
                    if (
                        response.body.statusMessage !== "Otp send Successfully"
                    ) {
                        this.toastr.success(
                            "OTP send on your register Mobile No",
                            "OTP Verification"
                        );
                    } else {
                        this.toastr.error(
                            "Incorrect username password.",
                            "Login Error"
                        );
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }
    verifyOTPtoSignIn(mobileNo: string, otp: string) {
        // Checking credentials and getting tokens for signing in user
        const param = {
            Username: mobileNo,
            Otp: otp,
        };
        const _spUrl = "AuthenticationWebApi/OTPBasedLogin";
        return this._genricCall.postMethodWithoutToken(_spUrl, param);
    }

    logout() {
        this._sessionCall.clearAllSession();
    }

    getToken() {
        return this.token;
    }

    isAuthenticated() {
        // here you can check if user is authenticated or not through his token
        return true;
    }
}
