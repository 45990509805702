import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { first } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    private currentHash = "{{POST_BUILD_ENTERS_HASH_HERE}}";

    private currentVersion = "";
    constructor(private http: HttpClient) {}

    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 30 minutes
     */
    public initVersionCheck(url) {
        this.checkVersion(url);
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private async checkVersion(url) {
        // url='assets/version.json';
        // timestamp these requests to invalidate caches
        this.http
            .get(url + "?t=" + new Date().getTime())
            .pipe(first())
            .subscribe((response: any) => {
                const hash = response.hash;
                const vers = response.version;

                const hashChanged = this.hasHashChanged(this.currentHash, hash);
                const versChanged = this.hasVersionChanged(
                    this.currentVersion,
                    vers
                );
                // If new version, do something
                if (hashChanged || versChanged) {
                    window.location.reload();
                } else {
                    console.log("No change");
                }
                // store the new hash so we wouldn't trigger versionChange again
                // only necessary in case you did not force refresh
                this.currentHash = hash;
                this.currentVersion = vers;
            });
    }
    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash, newHash) {
        if (!currentHash || currentHash === "{{POST_BUILD_ENTERS_HASH_HERE}}") {
            return false;
        }

        return currentHash !== newHash;
    }

    private hasVersionChanged(currentVersion, newVersion) {
        if (!currentVersion || currentVersion === "") {
            return false;
        }
        return currentVersion !== newVersion;
    }
}
