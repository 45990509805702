import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SignupComponent } from '../components/auth/signup/signup.component';
import { AuthModule } from '../components/auth/auth.module';



@NgModule({
    declarations: [
        
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AuthModule

    ],
    exports: [AuthModule]
})
export class SharedModule { }
