<app-header-one></app-header-one>

<div class="page-title-area page-title-retirement">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Retirement Plan</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Retirement Plan</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>Retirement Plan is a multiple-line insurance policy</h3>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Randomised words which don't slightly believable.</p> -->
                        <p>Retirement plans are commonly known as pension plans. Retirement plans can help an individual to build a corpus to fulfil their financial requirements and financial goals post retirement. Retirement plans are a type of life insurance plan and these plans can help an individual to create a regular flow of income post retirement. Most retirement plans offer a maturity benefit at the end of the policy tenure in case the life assured survives the entire policy tenure. Retirement plan can help an individual fulfill his/her post retirement goals such as buying a home, funding your child’s marriage, funding medical emergencies, going on dream vacation etc.</p>
                        <p>Retirement plans are also known as pension plans which are curated to help you fulfil your financial requirement post retirement. This type of life insurance plan helps you ensure you have a steady pension flow at regular intervals after your retirement. Some retirement plans give maturity benefits, which are lump sum payments made at the end of the policy term when the plan matures. Because retirement plans are a type of life insurance, they give life insurance and financial help to your family in case of your untimely death within the term of the policy.Retirement plans help you grow your corpus through compounding.</p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p> -->
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some for.</p> -->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="image text-center">
                        <img src="assets/img/services-image/retirement1.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="insurance-details-desc">
            <!-- <h3>Four major elements that we offer:</h3> -->
            <h3>Types of Pension Plans in India</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
                <p>To cater to the requirement of the insurance seekers, there is a wide range of Pension Plans available in the market. These plans have multiple classifications, based on the plan structure and benefits. These pension plans can be further divided into 8 categories:</p>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Deferred Annuity</li>
                        <li><i class="fas fa-check"></i>Immediate Annuity</li>
                        <li><i class="fas fa-check"></i>With Cover and Without Cover Pension Plans</li>
                        <li><i class="fas fa-check"></i>Annuity Certain</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Guaranteed Period Annuity</li>
                        <li><i class="fas fa-check"></i>Life Annuity</li>
                        <li><i class="fas fa-check"></i>National Pension Scheme (NPS)</li>
                        <li><i class="fas fa-check"></i>Pension Funds</li>
                    </ul>
                </div>
            </div>

            <h3>Setting the mood with incense</h3>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            <p>It is important to note that planning for retirement starts way before an individual gets retired—the sooner the better. Even though, the amount of money one requires to retire comfortably is entirely personalized, but there are various rules of thumb that can provide an idea of how much to save.</p>
            <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/retirement2.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/retirement3.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/retirement4.jpg" alt="image">
                    </figure>
                </li>
            </ul>

            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Here we have taken an example in order to help you understand how much retirement corpus one needs at the time of retirement.Mr. Kumar is 42 years old married man and is currently working in a private company in a position of senior product manager. His wife is only member of the family who is dependent on him. Mr. kumar wants to retire at the age of 60.He currently earns Rs.80,000 per month, while his monthly expense is Rs.52,000 including the insurance premium and mutual fund investments. He is adequately insured and has created an emergency fund of 6 months. Considering the above mentioned information, let’s take a look at how much retirement corpus he needs during retirement.</p>
            <blockquote class="wp-block-quote">
                <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> -->
                <p>The universe is like a pension plan.It will match your investment.</p>
                <cite>Tom Cruise</cite>
            </blockquote>
            
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
             <p>Grover Care is an online platform wherein you can compare and then buy the most suitable pension plan available in the market. Grover Care is tied up with leading life insurance providers ensuring that you get to buy the best plan. Moreover, Grover Care team provides personalised assistance both during the purchase of a plan and also at the time of sale. If you face any technical queries, you can seek help from Grover Care executives who help in solving your questions. There are educative insurance blogs on Grover Care website which educate customers and makes insurance simplified. So, you can choose Grover Care for buying the best pension plan available in the market.</p>
            <div class="truz-post-navigation">
                <div class="prev-link-wrapper">
                    <div class="info-prev-link-wrapper">
                        <a routerLink="/childcare-plans">
                            <span class="image-prev">
                                <img src="assets/img/services-image/child4.jpg" alt="image">
                                <span class="post-nav-title">Prev</span>
                            </span>
                            <span class="prev-link-info-wrapper">
                                <span class="prev-title">ChildCare Plan</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="next-link-wrapper">
                    <div class="info-next-link-wrapper">
                        <a routerLink="/general-insurance">
                            <span class="next-link-info-wrapper">
                                <span class="next-title">General Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                            <span class="image-next">
                                <img src="assets/img/services-image/general3.jpg" alt="image">
                                <span class="post-nav-title">Next</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
